import "./creativeBoost.scss";
import { Box } from "@mui/material";
import Image from "mui-image";
import creativeBoost from "../../../assets/images/creativeboost.png";

function CreativeBoost() {
	return (
		<>
			<Box className="flex flex-col items-center justify-center">
				<Box className="blur saturate-50 pointer-events-none select-none">
					<Image src={creativeBoost} />
				</Box>
				<Box className="absolute z-50 text-center">
					<h2 className="-bold -padded-2">Kommer snart!</h2>
					<p className="-large">
						Här hittar du så småningom artiklar, videos, webinar, workshops och
						mycket annat.
					</p>
				</Box>
			</Box>
		</>
	);
}

export default CreativeBoost;
