import { Box } from "@mui/material";
import "./footer.scss";
import { CompanyContext } from "../../../context/CompanyContext";
import { UserAuthContext } from "../../../context/UsersContext";
import { StorageContext } from "../../../context/StorageContext";
import { useContext } from "react";

function Footer() {
	const {
		companyInformation,
		companyId,
		handleOrderForm,
		orderIsOpen,
		getCompanyInformation,
	} = useContext(UserAuthContext);
	return (
		<Box className="footer-container">
			<Box>
				{companyInformation ? (
					<p className="-large">{companyInformation.companyName}</p>
				) : null}
			</Box>
			<Box>villkor & privacy</Box>
		</Box>
	);
}

export default Footer;
