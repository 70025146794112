import {
	Button,
	Typography,
	Tooltip,
	TooltipProps,
	tooltipClasses,
	styled,
} from "@mui/material";
import "./dashboardCard.scss";
import { UserAuthContext } from "../../../context/UsersContext";
import { StorageContext } from "../../../context/StorageContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import Image from "mui-image";

import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import FontsModal from "../modals/FontsModal";

import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FileUpload from "../fileUpload/FileUpload";
import FallbackImg from "../../../assets/images/fallback.jpg";

function DashboardCard(prop: { text: string; id: string }) {
	const {
		activeCompany,
		companyInformation,
		getCompanyInformation,
		companyId,
	} = useContext(UserAuthContext);

	const {
		downloadFolderAsZip,
		getCoverPhoto,
		uploadCoverPhoto,
		brandingCoverPhoto,
		logotypesCoverPhoto,
		fontsCoverPhoto,
		isCoverPhotoLoading,
		isAdminEditing,
	} = useContext(StorageContext);

	const [cardsArray, setCardsArray] = useState([]);
	const [showModal, setShowModal] = useState(false);
	// const fetchCoverPhotos = useMemo(() => getCoverPhoto(prop.text), [companyId]);

	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: theme.shadows[1],
			fontSize: 11,
			pointerEvents: "none",
		},
	}));

	useEffect(() => {
		getCompanyInformation(prop.id);
	}, [activeCompany]);

	const openModal = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};
	function capitalizeFirstLetterInString(string: string) {
		const baseString = string;
		const capitalizedString =
			baseString.charAt(0).toUpperCase() + baseString.slice(1);

		return capitalizedString;
	}

	return (
		<>
			<Box className="flex flex-col items-center">
				<Box className="flex flex-col items-center">
					<h2 className="-mb-2">{capitalizeFirstLetterInString(prop.text)}</h2>
					{isAdminEditing ? (
						<FileUpload
							coverPhoto={true}
							destination={`${prop.text}/coverPhoto`}
						/>
					) : null}
				</Box>
				<Box className="dashboardCard-card -flex -center-center -flex-column">
					<FontsModal show={showModal} close={closeModal} />
					{companyInformation ? (
						<>
							<Box className="object-cover cursor-pointer">
								{prop.text == "fonts" ? (
									<Box onClick={openModal} className="min-h-full">
										<Image
											showLoading
											duration={0}
											fit="cover"
											className="rounded-md max-h-[13rem] min-h-[13rem]"
											src={
												fontsCoverPhoto.length > 0
													? `${fontsCoverPhoto[0].customMetadata.downloadUrl}`
													: `${(<FallbackImg />)}`
											}
										/>
									</Box>
								) : (
									<Link to={`/${prop.text}`} className="min-h-full">
										<>
											{!isCoverPhotoLoading ? (
												<Image
													showLoading
													duration={0}
													fit="cover"
													className="rounded-md max-h-[13rem] min-h-[13rem]"
													src={
														prop.text === "branding" &&
														brandingCoverPhoto.length > 0
															? `${brandingCoverPhoto[0].customMetadata.downloadUrl}`
															: prop.text === "fonts" &&
															  fontsCoverPhoto.length > 0
															? `${fontsCoverPhoto[0].customMetadata.downloadUrl}`
															: prop.text === "logotypes" &&
															  logotypesCoverPhoto.length > 0
															? `${logotypesCoverPhoto[0].customMetadata.downloadUrl}`
															: `${(<FallbackImg />)}`
													}
												/>
											) : (
												<CircularProgress />
											)}
										</>
									</Link>
								)}
							</Box>
							<Box className="min-w-full">
								{prop.text === "fonts" ? (
									<button className="-button -mt-1" onClick={openModal}>
										<p className="-large -bold">Mina typsnitt</p>
									</button>
								) : (
									<button
										className="-button -mt-1"
										onClick={() => downloadFolderAsZip(prop.text)}
									>
										<p className="-large -bold">
											{prop.text === "branding"
												? "Ladda ner manual"
												: prop.text === "logotypes"
												? "Ladda ner logokit"
												: null}
										</p>
									</button>
								)}
							</Box>
							{prop.text !== "fonts" ? (
								<Box className="text-white min-w-full flex justify-around mt-2">
									<Link to={`/${prop.text}`} className="text-inherit">
										<Tooltip title={"Öppna filhanteraren"}>
											<FolderOpenOutlinedIcon fontSize="large" />
										</Tooltip>
									</Link>
									<Tooltip title={"Funktionen kommer snart"}>
										<DriveFileMoveOutlinedIcon
											sx={{ color: "#555f7f" }}
											fontSize="large"
										/>
									</Tooltip>
									<Tooltip title={"Funktionen kommer snart"}>
										<ShareOutlinedIcon
											sx={{ color: "#555f7f" }}
											fontSize="large"
										/>
									</Tooltip>
								</Box>
							) : null}
						</>
					) : (
						<CircularProgress />
					)}
				</Box>
			</Box>
		</>
	);
}

export default DashboardCard;
