import {
  Box,
  CircularProgress,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import Sidebar from "../activityPlan/calendarSidebar/Sidebar";
import Image from "mui-image";
import image1 from "../../../assets/images/SCR-20221006-d6a.png";
import image2 from "../../../assets/images/SCR-20221006-d5w.png";

import "./dashboard.scss";
import { useContext, useEffect, useMemo } from "react";
import { UserAuthContext } from "../../../context/UsersContext";
import { CompanyContext } from "../../../context/CompanyContext";
import { StorageContext } from "../../../context/StorageContext";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FileUpload from "../fileUpload/FileUpload";
import FallbackImg from "../../../assets/images/fallback.jpg";

interface Iprops {
  children?: any;
}

function Dashboard(props: Iprops) {
  const {
    userInformation,
    companyInformation,
    getCompanyInformation,
    companyId,
    orderIsOpen,
    handleOrderForm,
    contactIsOpen,
    handleContactForm,
    isAdmin,
  } = useContext(UserAuthContext);

  const { sortedEvents, listAllEvents, removeWWWWFromUrl, getCompanyEvents } =
    useContext(CompanyContext);

  const {
    downloadFolderAsZip,
    handleAdminEditing,
    isAdminEditing,
    coverPhoto,
  } = useContext(StorageContext);

  useEffect(() => {
    if (companyId) {
      getCompanyInformation(companyId);
      getCompanyEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <>
      {companyInformation ? (
        <Box className="flex flex-col items-center justify-center">
          <Box className="flex gap-4 justify-between w-full min-h-[100%]">
            <Box className="flex flex-col -padded-2">
              <Box className="max-w-[20rem]">
                <Box className="-mb-2">
                  <h2>
                    Välkommen,{" "}
                    {userInformation ? userInformation.firstName : null} 👋
                  </h2>
                </Box>
                <Box>
                  <p>
                    Här kan du hitta ditt företags grafiska element, ladda upp
                    viktiga dokument, planera event, beställa material och
                    mycket mer.
                  </p>
                </Box>
              </Box>

              <Box className="flex gap-4 -mt-2">
                <Box>
                  <button
                    onClick={() =>
                      orderIsOpen
                        ? handleOrderForm(false)
                        : handleOrderForm(true)
                    }
                    className="-button -filled -wide"
                  >
                    Beställ material
                  </button>
                </Box>
                <Box>
                  <button
                    onClick={() => {
                      contactIsOpen
                        ? handleContactForm(false)
                        : handleContactForm(true);
                    }}
                    className="-button -wide"
                  >
                    Kontakta support
                  </button>
                </Box>
              </Box>
            </Box>

            {/* EVENT STATS TBA */}

            {/* <LightTooltip followCursor={true} title="Statistik kommer snart">
							<Box className="flex flex-col items-center -padded-2 cursor-default select-none">
								<>
									<Box className="flex flex-col items-center -padded-2">
										<h2 className="-large -disabled">100</h2>
										<span className="-text-center -disabled">
											Aktiviteter <br /> planerade
										</span>
									</Box>
									<Box className="flex flex-col items-center -padded-2">
										<h2 className="-large -disabled">30</h2>
										<span className="-text-center -disabled">
											Aktiviteter <br /> genomförda
										</span>
									</Box>
								</>
							</Box>
						</LightTooltip> */}

            <Box className="flex flex-col items-center justify-center min-w-[25rem] bg-[#312B3E] -padded-2">
              <Box className="flex flex-col">
                <h1>{companyInformation.companyName}</h1>
                <a
                  href={`https://${companyInformation.url}`}
                  target="_blank"
                  className="-white"
                >
                  <h3>{removeWWWWFromUrl(companyInformation.url)}</h3>
                </a>
              </Box>
            </Box>
          </Box>
          {isAdmin && (
            <Box
              sx={{
                display: "flex",
                alignSelf: "flex-end",
              }}
              className="-pr-4 cursor-pointer -padded-2"
            >
              <EditIcon
                onClick={() => {
                  handleAdminEditing();
                }}
              />
            </Box>
          )}
          <Box className=" max-h-[25rem] flex justify-between gap-4">
            <Box>
              <Box className="flex flex-col items-start text-black max-h-[20rem] min-h-[100%] min-w-[20rem] bg-[#f6f7f9] -padded-4">
                <Box className="-mb-2">
                  <h2>Kommande aktiviteter</h2>
                </Box>
                <Box className="overflow-auto hide-scroll min-w-full">
                  {listAllEvents?.map((event: any, index: any) => (
                    <Sidebar
                      key={event.id}
                      title={event.title}
                      color={event.color}
                      dateFrom={event.start}
                      dateTo={event.end}
                      state={event.extendedProps.status}
                      id={event.id}
                    />
                  ))}
                </Box>
              </Box>
              <Box className="-mt-2">
                <Link className="-white -underline" to="/activityplan">
                  <p className="-large">Se alla aktiviteter</p>
                </Link>
              </Box>
            </Box>
            {isAdminEditing ? (
              <Box className="flex flex-col min-w-[20rem] items-center">
                <FileUpload coverPhoto={true} destination={"coverPhoto"} />
              </Box>
            ) : (
              <Box className="flex flex-col">
                <Box className="min-h-[100%] bg-[#f6f7f9] flex flex-col ">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "20rem",
                    }}
                    className="h-full"
                  >
                    {coverPhoto.length > 0 ? (
                      <Image
                        src={`${coverPhoto[0].customMetadata.downloadUrl}`}
                        duration={0}
                        className="max-w-[20rem]"
                        fit="cover"
                      />
                    ) : (
                      <Box className="min-h-full min-w-[320px] flex items-center justify-center">
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box className="-mt-2">
                  <Link className="-white -underline" to="/graphicprofile">
                    <p className="-large">Se din grafiska profil</p>
                  </Link>
                </Box>
              </Box>
            )}
            <Box className="flex flex-col items-center justify-between">
              <Box className="flex flex-col justify-center items-center bg-white text-black h-full w-full -padded-2">
                <Box>{/* plus-knapp */}+</Box>
                <Box>
                  <p>Lägg till material</p>
                </Box>
              </Box>
              <Box className="flex flex-col -padded-2">
                <Box>
                  <button
                    onClick={() => downloadFolderAsZip("branding")}
                    className="-button"
                  >
                    Ladda ner manual
                  </button>
                </Box>
                <Box className="max-w-[18rem] h-full -mt-2">
                  <p className="-text-center">
                    Din manual innehåller regler och ramverk kring hur ditt
                    företag ska synas. Klicka på knappen ovan för att ladda ner
                    den.
                  </p>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

export default Dashboard;
