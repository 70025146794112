import { Button, Box, Typography } from "@mui/material";
import CreateUserForm from "../forms/createUser/createUserForm";
import { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../../../context/SettingsContext";
import "./settings.scss";

function SettingsPage() {
	const { listAllUsers, listAllCompanies } = useContext(SettingsContext);
	const [createUser, setCreateUser] = useState<boolean>(false);
	const [createCompany, setCreateCompany] = useState<boolean>(false);
	const [hideButtons, setHideButtons] = useState<boolean>(false);

	function showUserCreation() {
		setCreateUser(true);
		setHideButtons(true);
	}

	function showCompanyCreation() {
		setCreateCompany(true);
		setHideButtons(true);
	}

	return (
		<Box className="settingsContainer">
			<Box className="-flex">
				<Box>
					<h2>ALL USERS</h2>
					{listAllUsers.map((user: any, i: number) => (
						<Box key={i}>
							<p>ID: {user.userInformation.uID}</p>
							<p>Firstname: {user.userInformation.firstName}</p>
							<p>Lastname: {user.userInformation.lastName}</p>
							<p>Email: {user.userInformation.email}</p>
							<p>Companies: </p>
							{user.userInformation.userCompanies.map(
								(companies: any, i: number) => (
									<p key={i}>{companies.companyName}</p>
								)
							)}
						</Box>
					))}
				</Box>
				<Box>
					<h2>ALL COMPANIES</h2>
					{/* {listAllCompanies.map((company: any, i: number) => (
						<Box key={i}>
							<p>Companyname: {company.companyName}</p>
							<p>Company users:</p>
							{company.users.map((user: any, i: number) => (
								<p key={i}>{user.firstName}</p>
							))}
						</Box>
					))} */}
				</Box>
			</Box>

			{!hideButtons ? (
				<>
					<Box className="settingsCard">
						<button className="-button" onClick={showUserCreation}>
							<p>Skapa Användare</p>
						</button>
					</Box>
					<Box className="settingsCard">
						<button className="-button" onClick={showCompanyCreation}>
							<p>Skapa Företag</p>
						</button>
					</Box>
				</>
			) : null}
			{createUser ? <CreateUserForm /> : null}
			{createCompany ? <h1>Skapa ett nytt företag</h1> : null}
			{listAllUsers.map((user: any, i: number) => (
				<Box key={i}>
					<p>{user.userinformation}</p>
				</Box>
			))}
		</Box>
	);
}

export default SettingsPage;
