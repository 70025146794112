import "./contactForm.scss";
import { UserAuthContext } from "../../../context/UsersContext";
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Iprops {}

function OrderForm(props: Iprops) {
	const { orderIsOpen, handleOrderForm, userInformation } =
		useContext(UserAuthContext);

	const [orderInput, setOrderInput] = useState({
		email: userInformation?.email,
		name: `${userInformation?.firstName} ${userInformation?.lastName}`,
		phoneNumber: userInformation?.phoneNumber,
		message: "",
		subject: "",
		date: "",
	});

	useEffect(() => {
		setOrderInput({
			email: userInformation?.email,
			name: `${userInformation?.firstName} ${userInformation?.lastName}`,
			phoneNumber: userInformation?.phoneNumber,
			message: "",
			subject: "",
			date: "",
		});
	}, [userInformation]);

	return (
		<>
			<Box className={orderIsOpen ? "formContainer -active" : "formContainer"}>
				<div className="w-full md:w-96 md:max-w-full mx-auto drop-shadow-md">
					<div className="p-6 border border-gray-300 sm:rounded-md bg-white">
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<CloseIcon
								sx={{ cursor: "pointer" }}
								onClick={() => {
									handleOrderForm(false);
								}}
								htmlColor="#001A2A"
							/>
						</Box>
						<form
							autoComplete="off"
							method="POST"
							action="https://public.herotofu.com/v1/f259d3e0-277a-11ed-8405-975da1fb4439"
						>
							<label className="block mb-6">
								<span className="text-gray-700">Ditt namn</span>
								<input
									required
									type="text"
									name="name"
									data-lpignore="true"
									className="
												block
												w-full
												mt-1
												border-gray-300
												rounded-md
												shadow-sm
												focus-visible:outline-none
												text-black
												px-1.5
												-padded-1
												caret-[#21aa9f]
											"
									value={orderInput.name}
									onChange={(e) => {
										setOrderInput({ ...orderInput, name: e.target.value });
									}}
								/>
							</label>
							<label className="block mb-6">
								<span className="text-gray-700">E-post</span>
								<input
									name="email"
									type="email"
									data-lpignore="true"
									className="
												block
												w-full
												mt-1
												border-gray-300
												rounded-md
												shadow-sm
												
												text-black
												px-1.5
												-padded-1
												focus-visible:outline-none
												caret-[#21aa9f]
											"
									required
									defaultValue={orderInput.email}
									onChange={(e) => {
										setOrderInput({ ...orderInput, email: e.target.value });
									}}
								/>
							</label>
							<label className="block mb-6">
								<span className="text-gray-700">Telefonnummer</span>
								<input
									name="phoneNumber"
									type="number"
									data-lpignore="true"
									className="
												block
												w-full
												mt-1
												border-gray-300
												rounded-md
												shadow-sm
												focus-visible:outline-none
												
												text-black
												px-1.5
												-padded-1
												caret-[#21aa9f]
											"
									required
									defaultValue={orderInput.phoneNumber}
									onChange={(e) => {
										setOrderInput({
											...orderInput,
											phoneNumber: e.target.value.toString(),
										});
									}}
								/>
							</label>
							<label className="block mb-6">
								<span className="text-gray-700">Önskat leveransdatum</span>
								<input
									name="date"
									type="date"
									data-lpignore="true"
									className="
												block
												w-full
												mt-1
												border-gray-300
												rounded-md
												shadow-sm
												focus-visible:outline-none
												
												text-black
												px-1.5
												-padded-1
												caret-[#21aa9f]
											"
									required
									defaultValue={orderInput.date}
									onChange={(e) => {
										setOrderInput({
											...orderInput,
											date: e.target.value.toString(),
										});
									}}
								/>
							</label>
							<label className="block mb-6">
								<span className="text-gray-700">Ämne</span>
								<input
									name="subject"
									required
									data-lpignore="true"
									className="
												block
												w-full
												mt-1
												border-gray-300
												rounded-md
												shadow-sm
												focus-visible:outline-none
												text-black
												px-1.5
												-padded-1
												caret-[#21aa9f]
											"
									defaultValue={orderInput.subject}
									placeholder="Vad gäller det?"
									onChange={(e) => {
										setOrderInput({
											...orderInput,
											subject: e.target.value,
										});
									}}
								/>
							</label>
							<label className="block mb-6">
								<span className="text-gray-700">Meddelande</span>
								<textarea
									name="message"
									required
									className="
												block
												w-full
												mt-1
												border-gray-300
												rounded-md
												shadow-sm
												focus-visible:outline-none
												text-black
												px-1.5
												messageInput
												-padded-1
												caret-[#21aa9f]
											"
									rows={3}
									placeholder="Hur kan vi hjälpa dig?"
									onChange={(e) => {
										setOrderInput({
											...orderInput,
											message: e.target.value,
										});
									}}
								></textarea>
							</label>
							<div className="mb-6">
								<button
									type="submit"
									className="
										-button
										h-10
										px-5
										bg-indigo-700
										rounded-lg
										transition-colors
										duration-150
										focus:shadow-outline
										hover:bg-indigo-800
										hover:text-white
										text-white
									"
								>
									Skicka meddelande
								</button>
							</div>
						</form>
					</div>
				</div>
			</Box>
		</>
	);
}

export default OrderForm;
