import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { UserAuthContext } from "../../../context/UsersContext";

import "./sideboard.scss";

import MenuUser from "./MenuUser";

import { useLocation } from "react-router-dom";
interface Iprops {
	children?: JSX.Element | JSX.Element[];
}

function Sideboard(props: Iprops) {
	const [selectedIndex, setSelectedIndex] = useState<any>(0);
	const { handleContactForm, handleOrderForm } = useContext(UserAuthContext);
	const [active, setActive] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setSelectedIndex(location.pathname);
	}, [location.pathname]);

	// lägg in dom andra pathsen när vi fixat routsen

	useEffect(() => {
		if (selectedIndex === "/fonts") {
			setSelectedIndex("/graphicprofile");
		}
	}, [selectedIndex]);

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number
	) => {
		setSelectedIndex(index);
	};

	useEffect(() => {
		if (selectedIndex !== 7) {
			handleContactForm(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIndex]);

	useEffect(() => {
		if (selectedIndex !== 5) {
			handleOrderForm(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIndex]);

	return (
		<>
			<Box className="hide-menu">
				<MenuUser
					selectedIndex={selectedIndex}
					handleListItemClick={handleListItemClick}
				/>
			</Box>
			{/* Mobil-menu  */}
			<Box onClick={() => setActive(!active)} className="wrapper">
				<Box className={active ? "activeHamburger" : "hamburger"} />
				<Box className={active ? "activeSidenav" : "sidenav"}>
					<Box className="sideboardContainerMobile">
						<MenuUser
							selectedIndex={selectedIndex}
							handleListItemClick={handleListItemClick}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
}

export default Sideboard;
