import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { ExportToCsv } from "export-to-csv";
import {
	arrayRemove,
	arrayUnion,
	doc,
	getDoc,
	setDoc,
	updateDoc,
	onSnapshot,
	collection,
	query,
	orderBy,
} from "firebase/firestore";

import { UserAuthContext } from "./UsersContext";
import { AnyRecord } from "dns";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import { resolve } from "path/posix";
import moment, { Moment } from "moment";
interface IState {
	listAllEvents: any;
	listAllFonts: any;
	eventAdded: boolean;
	sortedEvents: any;
}

interface ContextValue extends IState {
	getCompanyEvents: () => void;
	getCompanyFonts: () => void;
	downloadCSVdata: () => void;
	uploadSheetToDatabase: (file: any) => void;
	addFont: (font: any) => void;
	deleteFont: (font: any) => void;
	deleteEvent: (event: any) => void;
	createNewEvent: (event: any) => void;
	updateEvent: (event: any) => void;
	updateEventBoolean: (state: boolean) => void;
	removeWWWWFromUrl: (url: string) => string;
}

export const CompanyContext = createContext<ContextValue>({
	getCompanyEvents: () => {},
	getCompanyFonts: () => {},
	addFont: () => {},
	deleteFont: () => {},
	createNewEvent: () => {},
	updateEvent: () => {},
	updateEventBoolean: () => {},
	deleteEvent: () => {},
	downloadCSVdata: () => {},
	uploadSheetToDatabase: () => {},

	removeWWWWFromUrl: (string) => {
		return string;
	},

	listAllEvents: [],
	listAllFonts: [],
	eventAdded: false,
	sortedEvents: [],
});

interface Props {
	children: any;
}

function CompanyProvider(props: Props) {
	const { companyId, companyInformation } = useContext(UserAuthContext);
	const [listAllEvents, setListAllEvents] = useState<any>([]);
	const [listAllFonts, setListAllFonts] = useState<any>([]);
	const [sortedEvents, setSortedEvents] = useState<any>();
	const [eventAdded, setEventAdded] = useState<boolean>(false);
	const [show, setShow] = useState(false);
	const [headers, setHeaders] = useState([]);
	const [rows, setRows] = useState([]);

	const CSVoptions = {
		fieldSeparator: ",",
		quoteStrings: '"',
		decimalSeparator: ".",
		showLabels: true,
		showTitle: true,
		title: `${companyInformation?.companyName} event`,
		filename: `${companyInformation?.companyName} event`,
		useTextFile: false,
		useBom: false,
		useKeysAsHeaders: true,
		// headers: ["Start", "End", "Title"],
	};
	const csvExporter = new ExportToCsv(CSVoptions);

	function updateEventBoolean(state: boolean) {
		setEventAdded(state);
	}

	async function getCompanyEvents() {
		const Moment = require("moment");
		const unsub = onSnapshot(
			doc(db, "Companies", `${companyId}`),
			(snapshot) => {
				setListAllEvents(
					snapshot
						.data()
						?.events.sort(
							(a: any, b: any) =>
								new Moment(a.start).format("YYYYMMDD") -
								new Moment(b.start).format("YYYYMMDD")
						)
				);
			}
		);
		return unsub;
	}

	async function getCompanyFonts() {
		const docRef = doc(db, "Companies", `${companyId}`);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const newFontList = docSnap.data().fonts;
			setListAllFonts(newFontList);
		}
	}

	async function addFont(font: any) {
		const docRef = doc(db, "Companies", `${companyId}`);

		await updateDoc(docRef, {
			fonts: arrayUnion({
				name: font.name,
				url: font.url,
			}),
		});
	}

	async function deleteFont(font: any) {
		const docRef = doc(db, "Companies", `${companyId}`);

		await updateDoc(docRef, {
			fonts: arrayRemove({
				name: font.name,
				url: font.url,
			}),
		});
	}

	async function createNewEvent(event: any) {
		const docRef = doc(db, "Companies", `${companyId}`);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			if (companyInformation) {
				const newEvent = docSnap.data();
				newEvent.events.push(event);
				await setDoc(doc(db, "Companies", `${companyId}`), newEvent);

				setEventAdded(false);
			}
		}
	}

	async function updateEvent(event: any) {
		const docRef = doc(db, "Companies", `${companyId}`);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			if (companyInformation) {
				for (let i = 0; i < docSnap.data().events.length; i++) {
					let events = docSnap.data().events[i];

					if (docSnap.data().events[i].id === event.id) {
						deleteEvent(docSnap.data().events[i]);
						createNewEvent(event);
					}
				}

				setEventAdded(false);
			}
		}
	}

	async function deleteEvent(event: any) {
		const docRef = doc(db, "Companies", `${companyId}`);
		await updateDoc(docRef, {
			events: arrayRemove({
				color: event.color,
				end: event.end,
				extendedProps: {
					instructions: event.extendedProps.instructions,
					status: event.extendedProps.status,
				},
				id: event.id,
				start: event.start,
				title: event.title,
			}),
		});
	}

	async function uploadSheetToDatabase(file: any) {
		const docRef = doc(db, "Companies", `${companyId}`);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			await updateDoc(docRef, {
				events: file,
			});
		}
	}

	async function downloadCSVdata() {
		const docRef = doc(db, "Companies", `${companyId}`);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const events = docSnap.data().events;
			for (let i = 0; i < events.length; i++) {
				delete events[i].id;
				delete events[i].color;
				events[i].status = events[i].extendedProps.status;
				delete events[i].extendedProps;
			}
			console.log(events, "eventarray innan csv");
			if (events.length > 0) {
				csvExporter.generateCsv(events);
			}
		}
	}

	function removeWWWWFromUrl(url: string) {
		return url.replace(/www./, "");
	}

	return (
		<CompanyContext.Provider
			value={{
				getCompanyEvents: getCompanyEvents,
				getCompanyFonts: getCompanyFonts,
				addFont: addFont,
				deleteFont: deleteFont,
				createNewEvent: createNewEvent,
				updateEventBoolean: updateEventBoolean,
				updateEvent: updateEvent,
				removeWWWWFromUrl: removeWWWWFromUrl,
				deleteEvent: deleteEvent,
				downloadCSVdata: downloadCSVdata,
				uploadSheetToDatabase: uploadSheetToDatabase,

				sortedEvents: sortedEvents,
				listAllEvents: listAllEvents,
				listAllFonts: listAllFonts,
				eventAdded: eventAdded,
			}}
		>
			{props.children}
		</CompanyContext.Provider>
	);
}

export const SettingsConsumer = CompanyContext.Consumer;
export default CompanyProvider;
