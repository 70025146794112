import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { db, storage } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { saveAs } from "file-saver";
import JSZip from "jszip";

import {
	ref,
	uploadBytesResumable,
	getDownloadURL,
	listAll,
	getMetadata,
	updateMetadata,
	deleteObject,
	getStorage,
} from "firebase/storage";
import { UserAuthContext } from "./UsersContext";

interface IState {
	file: any;
	percent: number;
	data: any;
	arrayIsCleared: boolean;
	newFileIsUploaded: boolean;
	open: boolean;
	uploadedFile: string;
	fileArray: any;
	arrayToMap: any;
	coverPhoto: any;
	brandingCoverPhoto: any;
	fontsCoverPhoto: any;
	logotypesCoverPhoto: any;
	fileWasDeleted: boolean;
	deletedFile: string;
	showProgressBar: boolean;
	showFileName: boolean;
	isCoverPhotoLoading: boolean;
	isAdminEditing: boolean;
}

interface ContextValue extends IState {
	uploadFile: (destination: string, type: string) => void;
	uploadCoverPhoto: (destination: string, type: string) => void;
	handleChange: (event: any) => void;
	emptyFileArray: () => void;
	handleAdminEditing: () => void;
	handleClose: (event: any, reason: string) => void;
	convertFilesize: (size: number) => any;
	listAllFilesInFolder: (destination: string) => void;
	handleDelete: (destination: string, fileName: string) => void;
	downloadFolderAsZip: (destination: string) => void;
	getCoverPhoto: (destination: string) => void;
	getDashboardCoverPhoto: () => void;
}

export const StorageContext = createContext<ContextValue>({
	uploadFile: () => {},
	uploadCoverPhoto: () => {},
	handleChange: () => {},
	emptyFileArray: () => {},
	handleClose: () => {},
	convertFilesize: () => {},
	listAllFilesInFolder: () => {},
	handleDelete: () => {},
	downloadFolderAsZip: () => {},
	getCoverPhoto: () => {},
	getDashboardCoverPhoto: () => {},
	handleAdminEditing: () => {},
	file: "",
	percent: 0,
	data: "",
	arrayIsCleared: false,
	newFileIsUploaded: false,
	open: false,
	uploadedFile: "",
	deletedFile: "",
	fileArray: [],
	arrayToMap: [],
	coverPhoto: [],
	brandingCoverPhoto: [],
	fontsCoverPhoto: [],
	logotypesCoverPhoto: [],
	fileWasDeleted: false,
	showProgressBar: false,
	showFileName: false,
	isCoverPhotoLoading: false,
	isAdminEditing: false,
});

interface Props {
	children: any;
}

function StorageProvider(props: Props) {
	// State to store uploaded file
	const { companyId } = useContext(UserAuthContext);
	const [file, setFile] = useState<any>("");
	const [data, setData] = useState<any>([]);
	const [arrayIsCleared, setArrayIsCleared] = useState<boolean>(false);
	const [newFileIsUploaded, setNewFileIsUploaded] = useState<boolean>(false);
	const [fileArray] = useState<any>([]);
	const [arrayToMap, setArrayToMap] = useState<any>([]);
	const [coverPhoto, setCoverPhoto] = useState<any>([]);
	const [brandingCoverPhoto, setBrandingCoverPhoto] = useState<any>([]);
	const [fontsCoverPhoto, setFontsCoverPhoto] = useState<any>([]);
	const [logotypesCoverPhoto, setLogotypesCoverPhoto] = useState<any>([]);
	const [open, setOpen] = useState(false);
	const [fileWasDeleted, setFileWasDeleted] = useState(false);
	const [showProgressBar, setShowProgressBar] = useState(false);
	const [showFileName, setShowFileName] = useState(false);
	const [isCoverPhotoLoading, setIsCoverPhotoLoading] = useState(true);
	const [deletedFile, setDeletedFile] = useState("");
	const [uploadedFile, setUploadedFile] = useState("");
	const [isAdminEditing, setIsAdminEditing] = useState(false);

	// progress
	const [percent, setPercent] = useState(0);

	// Handle file upload event and update state
	function handleChange(event: any) {
		setFile(event);
		setShowFileName(true);
	}

	useEffect(() => {
		if (!companyId) return;
		const getCompanyData = async () => {
			const docRef = doc(db, "Companies", `${companyId}`);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setData(docSnap.data());
			}
		};
		getCompanyData();
		getDashboardCoverPhoto();
		getCoverPhoto("branding");
		getCoverPhoto("fonts");
		getCoverPhoto("logotypes");
	}, [companyId]);

	const handleClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
		setFileWasDeleted(false);
		setUploadedFile("");
	};

	function convertFilesize(size: number) {
		let totalSizeKB = size / Math.pow(1024, 1);
		let totalSizeMB = size / Math.pow(1024, 2);
		let totalSizeGB = size / Math.pow(1024, 3);

		if (size < 1048576) {
			return totalSizeKB.toFixed(1) + " KB";
		} else if (size < 1073741824) {
			return totalSizeMB.toFixed(2) + " MB";
		} else return totalSizeGB.toFixed(2) + " GB";
	}

	function handleAdminEditing() {
		setIsAdminEditing(!isAdminEditing);
	}

	const uploadFile = (destination: string, type: string) => {
		if (!file) {
			alert("Please upload a file first!");
		}

		const storageRef = ref(
			storage,
			`/companyUploads/${companyId}/${destination}/${file[0].name}`
		);

		// progress can be paused and resumed. It also exposes progress updates.
		// Receives the storage reference and the file to upload.
		const uploadTask = uploadBytesResumable(storageRef, file[0]);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const percent = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);

				// update progress
				setPercent(percent);
				setNewFileIsUploaded(false);
				setShowFileName(false);

				setShowProgressBar(true);
			},

			(err) => console.log(err),
			() => {
				// download url

				getDownloadURL(storageRef)
					.then((url: string) => {
						const metadata = {
							customMetadata: {
								downloadUrl: url,
							},
							contentType: type,
						};
						updateMetadata(storageRef, metadata);
						setNewFileIsUploaded(true);
						setUploadedFile(file[0].name.substring(0, 20));
						setFileWasDeleted(false);
						setOpen(true);
						setFile("");
					})
					.then(async () => {
						const listRef = ref(
							storage,
							`/companyUploads/${companyId}/${destination}/`
						);
						setShowProgressBar(false);
						const list = await listAll(listRef);
						const newItems = list.items.map((ref: any) => getMetadata(ref));
						const files = await Promise.all(newItems);
						setArrayToMap(files);
					});
			}
		);
	};

	function emptyFileArray() {
		setArrayToMap([]);
	}

	async function listAllFilesInFolder(destination: string) {
		setArrayIsCleared(true);
		const listRef = ref(
			storage,
			`/companyUploads/${companyId}/${destination}/`
		);

		const list = await listAll(listRef);
		const items = list.items.map((ref: any) => getMetadata(ref));
		const files = await Promise.all(items);
		setArrayToMap(files);
		setArrayIsCleared(false);
	}

	async function getCoverPhoto(destination: string) {
		setIsCoverPhotoLoading(true);
		setBrandingCoverPhoto("");
		setFontsCoverPhoto("");
		setLogotypesCoverPhoto("");
		const listRef = ref(
			storage,
			`/companyUploads/${companyId}/${destination}/coverPhoto`
		);

		const list = await listAll(listRef);

		const items = list.items.map((ref: any) => getMetadata(ref));
		const file = await Promise.all(items);

		if (destination === "branding") {
			setBrandingCoverPhoto(file);
		} else if (destination === "fonts") {
			setFontsCoverPhoto(file);
		} else if (destination === "logotypes") {
			setLogotypesCoverPhoto(file);
		}

		if (
			brandingCoverPhoto !== "" &&
			fontsCoverPhoto !== "" &&
			logotypesCoverPhoto !== ""
		) {
			setIsCoverPhotoLoading(false);
		}
	}

	async function getDashboardCoverPhoto() {
		setIsCoverPhotoLoading(true);
		setCoverPhoto("");
		const listRef = ref(storage, `/companyUploads/${companyId}/coverPhoto`);

		const list = await listAll(listRef);

		const items = list.items.map((ref: any) => getMetadata(ref));
		const file = await Promise.all(items);
		setCoverPhoto(file);

		if (coverPhoto !== "") {
			setIsCoverPhotoLoading(false);
		}
	}

	async function uploadCoverPhoto(destination: string, type: string) {
		if (!file) {
			alert("Please upload a file first!");
		}

		const storageRef = ref(
			storage,
			`/companyUploads/${companyId}/${destination}/`
		);
		// progress can be paused and resumed. It also exposes progress updates.
		// Receives the storage reference and the file to upload.
		const coverPhotoRef = ref(storageRef, "coverPhoto");
		const uploadTask = uploadBytesResumable(coverPhotoRef, file[0]);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const percent = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);

				// update progress
				setPercent(percent);
				setNewFileIsUploaded(false);
				setShowFileName(false);

				setShowProgressBar(true);
			},

			(err) => console.log(err),
			() => {
				// download url

				getDownloadURL(coverPhotoRef)
					.then((url: string) => {
						const metadata = {
							customMetadata: {
								downloadUrl: url,
							},
							contentType: type,
						};
						updateMetadata(coverPhotoRef, metadata);
						setNewFileIsUploaded(true);
						setUploadedFile(file[0].name.substring(0, 20));
						setFileWasDeleted(false);
						setOpen(true);
						setFile("");
					})
					.then(async () => {
						const listRef = ref(
							storage,
							`/companyUploads/${companyId}/${destination}`
						);
						setShowProgressBar(false);
						const list = await listAll(listRef);
						const newItems = list.items.map((ref: any) => getMetadata(ref));
						const files = await Promise.all(newItems);
						setArrayToMap(files);
					});
			}
		);
	}

	const handleDelete = (destination: string, fileName: string) => {
		const desertRef = ref(
			storage,
			`/companyUploads/${companyId}/${destination}/${fileName}`
		);

		// Delete the file
		deleteObject(desertRef)
			.then(async () => {
				const listRef = ref(
					storage,
					`/companyUploads/${companyId}/${destination}/`
				);

				const list = await listAll(listRef);
				const newItems = list.items.map((ref: any) => getMetadata(ref));
				const files = await Promise.all(newItems);
				setArrayToMap(files);
				setDeletedFile(fileName.substring(0, 20));
				setOpen(false);
				setFileWasDeleted(true);
			})
			.catch((error) => {
				// Uh-oh, an error occurred!
				console.log(error);
			});
	};

	const downloadFolderAsZip = async (destination: string) => {
		const jszip = new JSZip();
		const storage = getStorage();
		const folderRef = ref(
			storage,
			`/companyUploads/${companyId}/${destination}/`
		);
		const folder = await listAll(folderRef);
		const promises = folder.items
			.map(async (item) => {
				const file = await getMetadata(item);
				const fileRef = ref(storage, item.fullPath);
				const fileBlob = await getDownloadURL(fileRef).then(async (url) => {
					const response = await fetch(url);
					return await response.blob();
				});
				jszip.file(file.name, fileBlob);
			})
			.reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
		await promises;
		const blob = await jszip.generateAsync({ type: "blob" });
		saveAs(blob, `${destination}.zip`);
	};

	return (
		<StorageContext.Provider
			value={{
				uploadFile: uploadFile,
				uploadCoverPhoto: uploadCoverPhoto,
				handleChange: handleChange,
				emptyFileArray: emptyFileArray,
				handleClose: handleClose,
				convertFilesize: convertFilesize,
				listAllFilesInFolder: listAllFilesInFolder,
				handleDelete: handleDelete,
				downloadFolderAsZip: downloadFolderAsZip,
				getCoverPhoto: getCoverPhoto,
				getDashboardCoverPhoto: getDashboardCoverPhoto,
				handleAdminEditing: handleAdminEditing,
				isAdminEditing: isAdminEditing,
				showFileName: showFileName,
				showProgressBar: showProgressBar,
				deletedFile: deletedFile,
				fileWasDeleted: fileWasDeleted,
				file: file,
				fileArray: fileArray,
				percent: percent,
				data: data,
				arrayIsCleared: arrayIsCleared,
				newFileIsUploaded: newFileIsUploaded,
				open: open,
				uploadedFile: uploadedFile,
				arrayToMap: arrayToMap,
				coverPhoto: coverPhoto,
				brandingCoverPhoto: brandingCoverPhoto,
				fontsCoverPhoto: fontsCoverPhoto,
				logotypesCoverPhoto: logotypesCoverPhoto,
				isCoverPhotoLoading: isCoverPhotoLoading,
			}}
		>
			{props.children}
		</StorageContext.Provider>
	);
}

export const StorageConsumer = StorageContext.Consumer;
export default StorageProvider;
