import "./listItem.scss";
import { UserAuthContext } from "../../../../context/UsersContext";
import { StorageContext } from "../../../../context/StorageContext";
import { useContext, useState } from "react";
import {
	Box,
	Tooltip,
	TooltipProps,
	tooltipClasses,
	Typography,
	styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import { useConfirm } from "material-ui-confirm";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Image from "mui-image";
import moment, { Moment } from "moment";

interface Iprops {
	name: string;
	size: number;
	destination: string;
	url: string;
	type: string;
	date: string;
}

function ListItem(props: Iprops) {
	const { isAdmin, isOwner } = useContext(UserAuthContext);
	const { convertFilesize, handleDelete } = useContext(StorageContext);

	const [loading, setLoading] = useState<boolean>(true);
	const confirm = useConfirm();

	const documents = ["doc", "docx", "pdf", "word", "odt", "xls", "xlsx", "txt"];
	const presentations = ["ppt", "pptx"];
	const archives = ["zip", "rar", "7z", "s7z", "tgz"];

	function getFileType(filename: string) {
		console.log(
			filename.substring(filename.lastIndexOf(".") + 1, filename.length)
		);

		return (
			filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
			filename
		);
	}

	function removeExtension(filename: string) {
		return filename.substring(0, filename.lastIndexOf(".")) || filename;
	}

	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}));

	function downloadFile(url: string) {
		const xhr = new XMLHttpRequest();
		xhr.responseType = "blob";

		// Download file if it exists on server.
		xhr.onreadystatechange = function () {
			if (this.readyState === 4 && this.status === 200) {
				var downloadUrl = URL.createObjectURL(xhr.response);
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.href = downloadUrl;
				a.download = "";
				a.click();
			}
		};
		xhr.open("GET", url);

		xhr.send();
	}

	function formatDate(date: string) {
		let dateCreated = moment(date).format("DD MMM YYYY");

		return dateCreated;
	}

	const handleDownload = (url: string) => {
		downloadFile(url);
	};

	const handleClick = () => {
		confirm({
			description: "Filen raderas permanent.",
			title: "Är du säker på att du vill radera filen?",
			cancellationText: "Avbryt",
			confirmationButtonProps: { variant: "outlined", color: "error" },
			dialogProps: { maxWidth: "xs" },
		})
			.then(() => {
				/* ... */
				handleDelete(props.destination, props.name);
			})
			.catch(() => {
				/* ... */
			});
	};

	const handleLoad = () => {
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	return (
		<Box
			className={
				props.destination !== "/logotypes"
					? "listItem-container -flex"
					: "listItem-container -withImage -flex"
			}
		>
			{props.destination === "/logotypes" ? (
				<>
					<Box className="imageContainer">
						<Image
							onLoad={handleLoad}
							width="5rem"
							className="-padded-1"
							src={props.url}
							fit="fill"
							alt=""
							duration={125}
						/>
					</Box>
				</>
			) : null}

			{props.destination !== "/logotypes" ? (
				<>
					<>
						<Box className="imageContainer">
							{getFileType(props.name).match(
								/^(doc|docx|pdf|word|txt|xls|xlsx|csv)$/
							) ? (
								<TextSnippetOutlinedIcon fontSize="large" />
							) : getFileType(props.name).match(/^(ppt|pptx)$/) ? (
								<AssessmentOutlinedIcon fontSize="large" />
							) : getFileType(props.name).match(/^(zip|rar|7z|s7z|tgz)$/) ? (
								<FolderZipIcon fontSize="large" />
							) : (
								<AttachFileIcon fontSize="large" />
							)}
						</Box>
					</>
				</>
			) : null}

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					width: "20rem",
				}}
			>
				<Box mr={2} className="w-[10rem] flex flex-col">
					<LightTooltip title={props.name}>
						<Typography
							sx={{
								fontWeight: "bold",
							}}
							noWrap
						>
							{removeExtension(props.name)}
						</Typography>
					</LightTooltip>
					<Box>
						<p>{formatDate(props.date)}</p>
					</Box>
				</Box>
				<Box mr={2}>
					<LightTooltip title="Storlek">
						<Typography>{convertFilesize(props.size)}</Typography>
					</LightTooltip>
				</Box>
				<Box mr={2}>
					<LightTooltip title="Filtyp">
						<>
							{props.type ? (
								<Typography>.{getFileType(props.name)}</Typography>
							) : null}
						</>
					</LightTooltip>
				</Box>
			</Box>
			<Box
				sx={{
					minWidth: "6rem",
					display: "flex",
					justifyContent: "space-evenly",
					alignItems: "center",
				}}
			>
				{isAdmin || isOwner ? (
					<>
						{/* <LightTooltip title="Edit">
							<Box sx={{ cursor: "pointer" }}>
								<EditIcon />
							</Box>
						</LightTooltip> */}
						<LightTooltip title="Delete">
							<Box sx={{ cursor: "pointer" }}>
								<DeleteForeverIcon onClick={handleClick} />
							</Box>
						</LightTooltip>
					</>
				) : null}
				<LightTooltip title="Download">
					<Box sx={{ cursor: "pointer" }}>
						<DownloadIcon
							onClick={() => {
								handleDownload(props.url);
							}}
						/>
					</Box>
				</LightTooltip>
			</Box>
		</Box>
	);
}

export default ListItem;
