import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import "./createUser.scss";

function CreateUserForm() {
	const [updatedUserInfo, setUpdatedUserInfo] = useState<any>();
	const [newUser, setNewUser] = useState<any>({});

	function updateUserInfo(id: string, value: string) {
		setUpdatedUserInfo({
			...updatedUserInfo,
			[id]: value,
		});
	}

	function createNewUser(key: string, value: string) {
		const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
		const uniqid = randLetter + Date.now();
		setNewUser({
			...newUser,
			[key]: value,
			id: uniqid,
		});
	}

	const handleSubmit = (event: React.SyntheticEvent) => {};

	function handleChange(
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
	) {
		if (!event.target.id) {
			updateUserInfo(event.target.name, event.target.value);
			createNewUser(event.target.name, event.target.value);
		} else {
			updateUserInfo(event.target.id, event.target.value);
			createNewUser(event.target.id, event.target.value);
		}
	}

	return (
		<Box className="userFormContainer">
			<form
				autoComplete={"off"}
				onSubmit={handleSubmit}
				className="userForm"
				action=""
			>
				<TextField
					id="firstName"
					required
					className="inputField"
					inputProps={{ maxLength: 30 }}
					onChange={handleChange}
					variant="standard"
					margin="normal"
					placeholder={"Förnamn"}
					label="Förnamn"
					// defaultValue={props.isNewItem ? null : props.menuItem.title}
				/>
				<TextField
					id="lastName"
					required
					inputProps={{ maxLength: 30 }}
					onChange={handleChange}
					className="inputField"
					variant="standard"
					margin="normal"
					placeholder={"Efternamn"}
					label="Efternamn"
				/>
				<TextField
					id="email"
					required
					onChange={handleChange}
					className="inputField"
					variant="standard"
					margin="normal"
					placeholder={"Email"}
					label="Email"
				/>
				<TextField
					id="phone"
					required
					onChange={handleChange}
					className="inputField"
					variant="standard"
					margin="normal"
					placeholder={"Telefonnummer"}
					label="Telefonnummer"
				/>
				<TextField
					id="password"
					required
					onChange={handleChange}
					className="inputField"
					variant="standard"
					margin="normal"
					placeholder={"Lösenord"}
					label="Lösenord"
				/>
			</form>
		</Box>
	);
}

export default CreateUserForm;
