import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import { useEffect, useContext } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes,
} from "react-router-dom";
import Login from "./components/login/login/Login";
import Content from "./components/portal/content/Content";
import FileManager from "./components/portal/graphicProfile/fileManager/FileManager";
import { UserAuthContext } from "./context/UsersContext";
import GraphicProfile from "./components/portal/graphicProfile/GraphicProfile";
import ActivityPlan from "./components/portal/activityPlan/ActivityPlan";
import Dashboard from "./components/portal/dashboard/Dashboard";
import Settings from "./components/portal/settings/Settings";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import GraphicTemplates from "./components/portal/graphicTemplates/GraphicTemplates";
import Documents from "./components/portal/documents/Documents";
import CreativeBoost from "./components/portal/creativeBoost/CreativeBoost";

function App() {
	function createFontFamily(fontFamily: string) {
		return {
			h1: { fontFamily },
			fontSize: 1,
			h2: { fontFamily },
			h3: { fontFamily },
			h4: { fontFamily },
			h5: { fontFamily },
			h6: { fontFamily },
			subtitle1: { fontFamily },
			subtitle2: { fontFamily },
			body1: { fontFamily },
			body2: { fontFamily },
			button: { fontFamily },
			caption: { fontFamily },
			overline: { fontFamily },
		};
	}

	const theme = createTheme({
		palette: {
			primary: {
				main: "#75A0F5",
				light: "#FFF",
			},
			secondary: {
				main: "#150f24",
				dark: "#000",
			},
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
		typography: {
			h1: { fontSize: 2 },
		},

		components: {
			MuiTypography: {
				defaultProps: {
					fontFamily: ["proxima-nova", "sans-serif"].join(","),
				},
			},
			MuiButtonBase: {
				defaultProps: {
					// The props to change the default for.
					disableRipple: true, // No more ripple, on the whole application 💣!
				},
			},
		},
	});

	const { isAdmin, companyId, loggedIn, getCompanyInformation } =
		useContext(UserAuthContext);

	useEffect(() => {
		if (companyId) {
			getCompanyInformation(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);
	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Router>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/" element={<Content children={<Dashboard />} />} />
						<>
							{loggedIn ? (
								<>
									<Route
										path="/graphicprofile"
										element={
											loggedIn ? (
												<Content children={<GraphicProfile />} />
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/settings"
										element={
											loggedIn && isAdmin ? (
												<Content children={<Settings />} />
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/graphictemplates"
										element={
											loggedIn ? (
												<Content children={<GraphicTemplates />} />
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/documents"
										element={
											loggedIn ? (
												<Content children={<Documents />} />
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/activityplan"
										element={
											loggedIn ? (
												<Content children={<ActivityPlan />} />
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/creativeboost"
										element={
											loggedIn ? (
												<Content children={<CreativeBoost />} />
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/fonts"
										element={
											loggedIn ? (
												<Content
													children={<FileManager text="fonts" id={companyId} />}
												/>
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/branding"
										element={
											loggedIn ? (
												<Content
													children={
														<FileManager text="branding" id={companyId} />
													}
												/>
											) : (
												<Navigate to="/login" />
											)
										}
									/>
									<Route
										path="/logotypes"
										element={
											loggedIn ? (
												<Content
													children={
														<FileManager text="logotypes" id={companyId} />
													}
												/>
											) : (
												<Navigate to="/login" />
											)
										}
									/>
								</>
							) : (
								<Route path="/login" element={<Login />} />
							)}
						</>
					</Routes>
				</Router>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
export default App;
