import DashboardCard from "../dashboardCard/dashboardCard";
import "./graphicProfile.scss";
import { UserAuthContext } from "../../../context/UsersContext";
import { CompanyContext } from "../../../context/CompanyContext";
import { StorageContext } from "../../../context/StorageContext";
import { useContext, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import SimpleModal from "../modals/FontsModal";
import EditIcon from "@mui/icons-material/Edit";
function GraphicProfile() {
	const {
		companyInformation,
		companyId,
		getCompanyInformation,
		handleOrderForm,
		orderIsOpen,
	} = useContext(UserAuthContext);

	const { removeWWWWFromUrl } = useContext(CompanyContext);

	const { handleAdminEditing } = useContext(StorageContext);

	useEffect(() => {
		if (companyId) {
			getCompanyInformation(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				overflow: "auto",
			}}
			className="min-w-[70%] -mt-4 "
		>
			{companyId && companyInformation ? (
				<>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
						className="min-h-[30rem]"
					>
						<Box className="min-w-full flex justify-between -padded-4 items-center -mb-4">
							<Box className="flex flex-col items-start">
								<Box>
									<h2>{companyInformation.companyName}</h2>
								</Box>
								<a
									href={`https://${companyInformation.url}`}
									target="_blank"
									className="-white"
								>
									<p className="-large">
										{removeWWWWFromUrl(companyInformation.url)}
									</p>
								</a>
							</Box>
							<Box className="flex items-center">
								<button
									className="-button -filled -padded-2"
									onClick={() =>
										orderIsOpen ? handleOrderForm(false) : handleOrderForm(true)
									}
								>
									<p className="-large -bold">Beställ material</p>
								</button>
								<Box className="-padded-2 cursor-pointer">
									<EditIcon
										onClick={() => {
											handleAdminEditing();
										}}
									/>
								</Box>
							</Box>
						</Box>
						<Box className="flex">
							<DashboardCard text="branding" id={companyId} />
							<DashboardCard text="fonts" id={companyId} />
							<DashboardCard text="logotypes" id={companyId} />
						</Box>
					</Box>
				</>
			) : (
				<CircularProgress />
			)}
		</Box>
	);
}

export default GraphicProfile;
