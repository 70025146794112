import { Box, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { IncomingUser } from "../../../types/types";
import LoginInputForm from "../loginform/LoginInputForm";
import { auth } from "../../../firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { Navigate } from "react-router-dom";

function Login() {
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [, setUid] = useState<string>();
  const [wrongPasswordOrEmail, setWrongPasswordOrEmail] =
    useState<boolean>(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, [isLoggedIn]);

  useEffect(() => {
    document.title = "Utmärkt Studio";
  }, []);

  //async funtion to log the user in
  async function loginDataCallback(user: IncomingUser) {
    await signInWithEmailAndPassword(auth, user.email, user.password)
      .then(async (cred) => {})
      .catch((err) => {
        if (err) {
          setWrongPasswordOrEmail(true);
        }
      });
  }

  if (isLoggedIn === undefined) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      {isLoggedIn ? <Navigate to={`/`} /> : null}
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "50rem",
            lg: "50rem",
            xl: "100%",
          },
        }}
      >
        <Box className={classes.paperBackgroundImgStyling} sx={{}}>
          <Box>
            <LoginInputForm
              incorrectInfo={wrongPasswordOrEmail}
              loginDataCallback={loginDataCallback}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    backgroundColor: "#001A2A",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },

  logo: {
    display: "flex",
    width: "11rem",
    padding: "0 0 0 0",
  },
  backgroundColor: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    backgroundColor: "beige",
    width: "100%",
    boxShadow: "-20px 0px 17px rgba(0, 0, 0, 0.03)",
  },
  logoContainer: {
    display: "flex",
    paddingTop: "5rem",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "#FEFEFE",
  },
  welcomeTextContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // marginTop: "2rem",
    paddingTop: "5rem",
    // backgroundColor: "#FEFEFE",
  },
  welcomeText: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    color: "#fefefe",
    margin: ".5rem 0 .4rem",
  },
  noAccountOuterContainer: {
    width: "100%",
    display: "flex",
    // alignItems: "flex-end",
    justifyContent: "center",
    position: "absolute",
    left: "25%",
    [theme.breakpoints.down("lg")]: {
      left: "50%",
    },
    bottom: "-18px",
    transform: "translate(-50%, -50%)",
    margin: "0 auto",
  },
  noAccountInnerContainer: {
    backgroundColor: "#C7C0AE",
    backgroundPosition: "center",
    backgroundSize: "fill",
    backgroundRepeat: "no-repeat",
    border: "1px solid transparent",
    padding: "0.3rem .8rem",
    borderRadius: "18px 18px 0 0",
  },
  paperBackgroundImgStyling: {
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "cover",
    },
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sideImgContainer: {
    width: "100%",
    height: "100%",
    borderRadius: "150px 0 0 150px",
    boxShadow: "-20px 0px 20px 0px rgb(0 0 0 / 3%);",
  },
  loader: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default Login;
