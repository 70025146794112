import { Box, MenuItem, Typography } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { UserAuthContext } from "../../../context/UsersContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase";

import "./sideboard.scss";
interface Iprops {}

function SelectCompany(props: Iprops) {
	const [userCompanies, setUserCompanies] = useState<any>([]);
	const {
		userInformation,
		updateActiveCompany,
		activeCompany,
		companyId,
		getCompanyInformation,
	} = useContext(UserAuthContext);
	const [, setIsAdmin] = useState<boolean>(false);
	const [, setIsLoggedIn] = useState<boolean>(true);

	const handleChange = (event: SelectChangeEvent) => {
		updateActiveCompany(event.target.value);
	};
	useEffect(() => {
		if (companyId) {
			getCompanyInformation(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);

	useEffect(() => {
		checkIfOwner();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInformation]);

	useEffect(() => {
		if (userInformation) {
			setUserCompanies(userInformation.userCompanies);
		}
	}, [userInformation]);

	function checkIfOwner() {
		if (userInformation && userInformation.role === "admin") {
			setIsAdmin(true);
		}
	}

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (!user) {
				setIsLoggedIn(false);
			}
		});
	}, []);

	return (
		<Box className="companySelectContainer -padded-2" mt={1}>
			{activeCompany !== null ? (
				<FormControl fullWidth size="small">
					<InputLabel id="chosenCompany" disabled={true}></InputLabel>

					<Select
						displayEmpty={true}
						variant="standard"
						labelId="select-company-label"
						id="select-company"
						value=""
						label="Company"
						onChange={handleChange}
						renderValue={
							activeCompany === ""
								? undefined
								: () => <p>{activeCompany?.companyName}</p>
						}
					>
						{userCompanies.map((company: any) => (
							<MenuItem value={company} key={company.companyId}>
								<p>{company.companyName}</p>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : (
				<p>Loading</p>
			)}
		</Box>
	);
}

export default SelectCompany;
