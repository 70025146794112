import { Box, Button, Theme } from "@mui/material";
import { auth } from "../../../firebase";
import LogoutIcon from "@mui/icons-material/Logout";

interface Iprops {}

function LogOutBtn(props: Iprops) {
	async function logOut() {
		await auth.signOut();
	}

	return (
		<>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Button size="medium" color="error" variant="outlined" onClick={logOut}>
					<Box mr={2} sx={{ display: "flex", alignItems: "center" }}>
						<LogoutIcon />
					</Box>
					<a className="logOutText" href="/login">
						Logga ut
					</a>
				</Button>
			</Box>
		</>
	);
}

export default LogOutBtn;
