import "./sideboard.scss";

import {
	Box,
	ListItemIcon,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
} from "@mui/material";

//Icons
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import FeedIcon from "@mui/icons-material/Feed";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";

import LogOutBtn from "../../login/logout/LogOutBtn";
import SelectCompany from "./SelectCompany";
import logotype from "../../../assets/images/Utmarkt_studio_dark.svg";

import { Link } from "react-router-dom";
interface Iprops {
	selectedIndex: any;
	handleListItemClick: any;
}

function MenuAdmin({ selectedIndex, handleListItemClick }: Iprops) {
	return (
		<Box className="sideboardContainerDesktop">
			<Box className="listContainer -mt-3 -padded-2">
				<Box className="flex flex-col justify-start items-center">
					<Link to="/">
						<img className="w-[14rem]" src={logotype} alt="" />
					</Link>
					<Box className="-mt-3">
						<List>
							<ListItem disablePadding disableGutters>
								<Link className="listItemLink" to="/">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/"}
										onClick={(event) => handleListItemClick(event, "/")}
									>
										<ListItemIcon>
											<DashboardIcon />
										</ListItemIcon>
										<ListItemText primary="Dashboard" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/graphicprofile">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/graphicprofile"}
										onClick={(event) =>
											handleListItemClick(event, "/graphicprofile")
										}
									>
										<ListItemIcon>
											<FormatColorTextIcon />
										</ListItemIcon>
										<ListItemText primary="Grafisk profil" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/graphictemplates">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/graphictemplates"}
										onClick={(event) =>
											handleListItemClick(event, "/graphictemplates")
										}
									>
										<ListItemIcon>
											<FormatPaintIcon />
										</ListItemIcon>
										<ListItemText primary="Grafiska mallar" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/documents">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/documents"}
										onClick={(event) =>
											handleListItemClick(event, "/documents")
										}
									>
										<ListItemIcon>
											<FeedIcon />
										</ListItemIcon>
										<ListItemText primary="Dokument" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/activityplan">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/activityplan"}
										onClick={(event) =>
											handleListItemClick(event, "/activityplan")
										}
									>
										<ListItemIcon>
											<CalendarMonthOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Aktivitetsplan" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/creativeboost">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/creativeboost"}
										onClick={(event) =>
											handleListItemClick(event, "/creativeboost")
										}
									>
										<ListItemIcon
											color={
												selectedIndex === "/creativeboost" ? "#000" : "fefefe"
											}
										>
											<RocketLaunchOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Kreativ boost" />
									</ListItemButton>
								</Link>
							</ListItem>
						</List>
					</Box>
					<Box>
						<List>
							<Box className="dropDownContainer -mt-3">
								<SelectCompany />
							</Box>

							<ListItem disablePadding className="-mt-3">
								<Link className="listItemLink" to="/settings">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/settings"}
										onClick={(event) => handleListItemClick(event, "/settings")}
									>
										<ListItemIcon>
											<SettingsOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Inställningar" />
									</ListItemButton>
								</Link>
							</ListItem>
						</List>
					</Box>
				</Box>
				<Box className="flex flex-col items-end">
					<List>
						<ListItem>
							<LogOutBtn />
						</ListItem>
					</List>
				</Box>
			</Box>
		</Box>
	);
}

export default MenuAdmin;
