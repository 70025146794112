import "./header.scss";
import { UserAuthContext } from "../../../context/UsersContext";
import { useContext } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Badge, Box, Icon } from "@mui/material";
import { Link } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

function Header() {
	// const { isAdmin } = useContext(UserAuthContext);
	return (
		<Box className="header-container -flex -align-center">
			<Breadcrumbs />
		</Box>
	);
}

export default Header;

// HEADER W/ SETTINGS, BELL &  AVATAR
//  {
// 		!isAdmin ? (
// 			<Box className="header-icons -flex -align-center -justify-space-between">
// 				<Icon color="inherit" className="header-icon">
// 					<Link to="/settings">
// 						<SettingsOutlinedIcon />
// 					</Link>
// 				</Icon>
// 				<Icon color="inherit" className="header-icon">
// 					{/* TODO logic for badge! */}
// 					<Badge badgeContent={0} variant="dot" overlap="circular">
// 						<NotificationsNoneIcon className="header-icon" />
// 					</Badge>
// 				</Icon>
// 				<Icon color="inherit">
// 					<AccountCircleIcon className="avatar-icon" fontSize="large" />
// 				</Icon>
// 			</Box>
// 		) : (
// 			<Box className="header-icons -flex -align-center -justify-end">
// 				<Icon color="inherit" className="header-icon">
// 					<Link to="/settings">
// 						<SettingsOutlinedIcon />
// 					</Link>
// 				</Icon>
// 			</Box>
// 		);
//  }
