import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import UserAuthProvider from "./context/UsersContext";
import StorageProvider from "./context/StorageContext";
import { ConfirmProvider } from "material-ui-confirm";
import SettingsProvider from "./context/SettingsContext";
import CompanyProvider from "./context/CompanyContext";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	// <React.StrictMode>
	<UserAuthProvider>
		<CompanyProvider>
			<SettingsProvider>
				<StorageProvider>
					<ConfirmProvider>
						<App />
					</ConfirmProvider>
				</StorageProvider>
			</SettingsProvider>
		</CompanyProvider>
	</UserAuthProvider>
	// </React.StrictMode>
);
