import { Box, Chip } from "@mui/material";
import moment from "moment";
import "moment/locale/sv";
import "./sidebar.scss";
import Fade from "react-reveal/Fade";
import { useEffect } from "react";

const Sidebar = (prop: {
  title: string;
  color: string;
  dateFrom: string;
  dateTo: string;
  state: string;
  id: string;
}) => {
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  moment.locale("sv");

  const calculateTimeDiff = () => {
    let start = moment(prop.dateFrom);
    let end = moment(prop.dateTo);
    return start.diff(end, "days");
  };

  return (
    <Fade bottom>
      <Box
        className={`flex items-center ${
          moment(prop.dateTo).isBefore() ? "hidden" : "block"
        } max-w-full min-h-[2.5rem] border-l-4 -mb-1 `}
        sx={{ borderColor: `${prop.color}` }}
      >
        <Box className="flex flex-col -pl-2">
          <Box>
            <p className="-small -faded">
              {calculateTimeDiff() < -1
                ? moment(prop.dateFrom).format("D MMMM") +
                  " - " +
                  moment(prop.dateTo).format("D MMMM")
                : moment(prop.dateFrom).format("D MMMM")}
            </p>
          </Box>
          <Box className="flex flex-col items-start">
            <Box>
              <p className="-bold">{capitalizeFirstLetter(prop.title)}</p>
            </Box>
            {prop.state ? (
              <Box
                sx={{ backgroundColor: `${prop.color}` }}
                className={`max-h-[1rem] flex items-center justify-start -padded-1`}
              >
                <span className="text-white" style={{ fontSize: ".7rem" }}>
                  {prop.state}
                </span>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default Sidebar;
