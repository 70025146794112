import { useContext, useEffect } from "react";
import { UserAuthContext } from "../../../context/UsersContext";
import "./sideboard.scss";

import {
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemIcon,
	Tooltip,
	TooltipProps,
	tooltipClasses,
	styled,
} from "@mui/material";

//Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import FeedIcon from "@mui/icons-material/Feed";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";

import LogOutBtn from "../../login/logout/LogOutBtn";
import logotype from "../../../assets/images/Utmarkt_studio_dark.svg";
import SelectCompany from "./SelectCompany";

import { Link } from "react-router-dom";
interface Iprops {
	selectedIndex: any;
	handleListItemClick: any;
}

function MenuUser({ selectedIndex, handleListItemClick }: Iprops) {
	const {
		companyId,
		getCompanyInformation,
		handleContactForm,
		handleOrderForm,
		contactIsOpen,
		orderIsOpen,
	} = useContext(UserAuthContext);

	useEffect(() => {
		if (companyId) {
			getCompanyInformation(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);

	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}));

	return (
		<Box className="sideboardContainerDesktop">
			<Box className="listContainer -mt-3 -padded-2">
				<Box className="flex flex-col justify-start items-center">
					<Link to="/">
						<img className="w-[14rem]" src={logotype} alt="" />
					</Link>
					<Box className="-mt-3">
						<List>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/"}
										onClick={(event) => handleListItemClick(event, "/")}
									>
										<ListItemIcon>
											<DashboardIcon />
										</ListItemIcon>
										<ListItemText primary="Dashboard" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/graphicprofile">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/graphicprofile"}
										onClick={(event) =>
											handleListItemClick(event, "/graphicprofile")
										}
									>
										<ListItemIcon>
											<FormatColorTextIcon />
										</ListItemIcon>
										<ListItemText primary="Grafisk profil" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/graphictemplates">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/graphictemplates"}
										onClick={(event) =>
											handleListItemClick(event, "/graphictemplates")
										}
									>
										<ListItemIcon>
											<FormatPaintIcon />
										</ListItemIcon>
										<ListItemText primary="Grafiska mallar" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/documents">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/documents"}
										onClick={(event) =>
											handleListItemClick(event, "/documents")
										}
									>
										<ListItemIcon>
											<FeedIcon />
										</ListItemIcon>
										<ListItemText primary="Dokument" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/activityplan">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/activityplan"}
										onClick={(event) =>
											handleListItemClick(event, "/activityplan")
										}
									>
										<ListItemIcon>
											<CalendarMonthOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Aktivitetsplan" />
									</ListItemButton>
								</Link>
							</ListItem>
							<ListItem disablePadding>
								<Link className="listItemLink" to="/creativeboost">
									<ListItemButton
										disableRipple
										selected={selectedIndex === "/creativeboost"}
										onClick={(event) =>
											handleListItemClick(event, "/creativeboost")
										}
									>
										<ListItemIcon>
											<RocketLaunchOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Kreativ boost" />
									</ListItemButton>
								</Link>
							</ListItem>
						</List>
					</Box>
					<Box>
						<List>
							<ListItem className="listItemLink" disablePadding>
								<ListItemButton
									disableRipple
									selected={selectedIndex === 5}
									onClick={(event) => {
										handleListItemClick(event, 5);
										orderIsOpen
											? handleOrderForm(false)
											: handleOrderForm(true);
									}}
								>
									<ListItemText primary="Beställ material" />
								</ListItemButton>
							</ListItem>
							<LightTooltip
								sx={{ marginTop: "2rem" }}
								followCursor={true}
								title={"Funktionen kommer snart"}
							>
								<ListItem disablePadding>
									<ListItemButton
										disabled
										disableRipple
										selected={selectedIndex === 6}
										onClick={(event) => handleListItemClick(event, 6)}
									>
										<ListItemText primary="Mina beställningar" />
									</ListItemButton>
								</ListItem>
							</LightTooltip>

							<ListItem disablePadding>
								<ListItemButton
									disableRipple
									selected={selectedIndex === 7}
									onClick={(event) => {
										handleListItemClick(event, 7);
										contactIsOpen
											? handleContactForm(false)
											: handleContactForm(true);
									}}
								>
									<ListItemText primary="Kontakt" />
								</ListItemButton>
							</ListItem>
							<LightTooltip
								sx={{ marginTop: "2rem" }}
								followCursor={true}
								title={"Funktionen kommer snart"}
							>
								<ListItem disablePadding>
									<ListItemButton
										disabled
										disableRipple
										selected={selectedIndex === 8}
										onClick={(event) => handleListItemClick(event, 8)}
									>
										<ListItemText primary="Mina ärenden" />
									</ListItemButton>
								</ListItem>
							</LightTooltip>
							<Box className="dropDownContainer">
								<SelectCompany />
							</Box>
						</List>
					</Box>
				</Box>
				<Box className="flex flex-col items-end">
					<List>
						<ListItem>
							<LogOutBtn />
						</ListItem>
					</List>
				</Box>
			</Box>
		</Box>
	);
}

export default MenuUser;
