import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "./activityPlan.scss";
import FullCalendar, { preventDefault } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import moment, { Moment } from "moment";
import { CompanyContext } from "../../../context/CompanyContext";
import { UserAuthContext } from "../../../context/UsersContext";
import { StorageContext } from "../../../context/StorageContext";
import { useContext, useEffect, useState } from "react";
import {
  DateTimePicker,
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import Papa from "papaparse";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { TwitterPicker, CirclePicker } from "react-color";
import Sidebar from "./calendarSidebar/Sidebar";
import { listAll } from "firebase/storage";
import TransitionGroup from "react-transition-group/TransitionGroup";
import Fade from "react-reveal/Fade";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileUpload from "../fileUpload/FileUpload";

function ActivityPlan() {
  const {
    companyInformation,
    companyId,
    handleOrderForm,
    orderIsOpen,
    getCompanyInformation,
  } = useContext(UserAuthContext);
  const {
    createNewEvent,
    listAllEvents,
    getCompanyEvents,
    updateEventBoolean,
    updateEvent,
    deleteEvent,
    downloadCSVdata,
    uploadSheetToDatabase,
  } = useContext(CompanyContext);
  const { downloadFolderAsZip } = useContext(StorageContext);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [uploadEventsModalIsOpen, setUploadEventsModalIsOpen] =
    useState<boolean>(false);
  const [colorPreview, setColorPreview] = useState<any>("");

  const [eventObject, setEventObject] = useState<any>({
    title: "",
    start: "",
    end: "",
    color: "#f44336",
    id: "",
    extendedProps: { status: "Planerad", instructions: "" },
  });

  const [updatedEventlist, setUpdatedEventList] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [eventsToUpload, setEventsToUpload] = useState<any>("");

  const colors = [
    "#f44336",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#ffc107",
    "#ff9800",
    "#ff5722",
  ];

  const handleOpen = (dateStart: any, dateEnd: any, title?: any) => {
    setIsEditing(false);
    setEventObject({
      color: "#f44336",
      start: dateStart,
      end: dateEnd,
      id: "",
      title: "",
      extendedProps: {
        status: "Planerad",
        instructions: "",
      },
    });

    setModalIsOpen(true);
  };

  const handleEdit = (
    dateStart: any,
    dateEnd: any,
    title: any,
    id: any,
    customData: any,
    color: string
  ) => {
    setIsEditing(true);

    setEventObject({
      color: color,
      start: dateStart,
      end: dateEnd,
      id: id,
      title: title,
      extendedProps: {
        instructions: customData.instructions,
        status: customData.status,
      },
    });
    setModalIsOpen(true);
  };

  const handleDragStart = (
    dateStart: any,
    dateEnd: any,
    title: any,
    id: any,
    customData: any,
    color: string
  ) => {
    // console.log(eventObject);
  };
  const handleDragEnd = (
    dateStart: any,
    dateEnd: any,
    title: any,
    id: any,
    customData: any,
    color: string
  ) => {
    setEventObject({
      color: color,
      start: dateStart,
      end: dateEnd,
      id: id,
      title: title,
      extendedProps: {
        instructions: customData.instructions,
        status: customData.status,
      },
    });
    updateEvent(eventObject);
  };

  const handleEventUpload = (e: any) => {
    e.preventDefault();
    // uploadSheetToDatabase(eventsToUpload);
    console.log(eventsToUpload);
  };

  const handleEventsToUpload = (e: any) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any, file: any) {
        setEventsToUpload(results.data);
      },
    });
  };

  const handleEventChange = () => {
    updateEvent(eventObject);
  };

  const handleClose = () => {
    setIsEditing(false);
    setColorPreview("");
    setModalIsOpen(false);
  };

  const handleDelete = () => {
    deleteEvent(eventObject);
    handleClose();
  };

  useEffect(() => {
    if (companyId) {
      getCompanyInformation(companyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      getCompanyEvents();
    }
  }, [companyId]);

  function getRandomInt(max: any) {
    return Math.floor(Math.random() * max).toString();
  }

  const addEvent = async (e: any, eventObject: any) => {
    e.preventDefault();
    const docRef = doc(db, "Companies", `${companyId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const eventToAdd = eventObject;
      createNewEvent({ ...eventToAdd, id: getRandomInt(5428324389) });
      // getCompanyEvents();

      handleClose();
    }
  };

  const updateCurrentEvent = async (e: any, eventObject: any) => {
    e.preventDefault();
    const docRef = doc(db, "Companies", `${companyId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const eventToAdd = eventObject;
      updateEvent(eventToAdd);
      // getCompanyEvents();

      handleClose();
      setIsEditing(false);
    }
  };

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fcfcfc",
    boxShadow: 24,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    zIndex: 9999,
    justifyContent: "center",
    p: 4,
    color: "black",
    borderLeft: `${
      eventObject.color !== ""
        ? `12px solid ${eventObject.color}`
        : "5px solid white"
    }`,
  };

  const uploadEventsModalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    minHeight: "20rem",
    bgcolor: "#fcfcfc",
    boxShadow: 24,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    zIndex: 9999,
    justifyContent: "center",
    p: 4,
    color: "black",
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0rem 2rem 0rem 2rem",
          overflow: "auto",
        }}
      >
        {companyInformation ? (
          <>
            <Box className="calendarContainer flex items-start max-h-full justify-center">
              <Box
                sx={{
                  width: "70%",
                  padding: "1.5rem",
                  backgroundColor: "#fefefe",
                  borderRadius: "10px",
                  maxWidth: "60rem",
                  userSelect: "none",
                  marginTop: "4rem",
                }}
              >
                <Modal
                  open={modalIsOpen}
                  onClose={handleClose}
                  style={{ position: "absolute" }}
                  container={() =>
                    document.getElementById("dashboard-container")
                  }
                  BackdropProps={{ style: { position: "absolute" } }}
                >
                  <Box sx={modalStyle}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          color: "black",
                          width: "100%",
                          paddingBottom: "4rem",
                        }}
                      >
                        <button
                          className="cursor-pointer delete-event"
                          onClick={() => handleDelete()}
                        >
                          <DeleteForeverIcon
                            sx={{
                              fontSize: "16px",
                            }}
                          />
                          <p>Radera aktivitet</p>
                        </button>
                        <button className="cursor-pointer close-button">
                          <CloseRoundedIcon
                            fontSize="large"
                            onClick={handleClose}
                          />
                        </button>
                      </Box>
                      <form
                        onSubmit={(e) =>
                          isEditing
                            ? updateCurrentEvent(e, eventObject)
                            : addEvent(e, eventObject)
                        }
                      >
                        <Stack spacing={3}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h2 className="">Redigera aktivitet</h2>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p className="-faded -pr-1">Status:</p>
                              <Select
                                value={eventObject.extendedProps.status}
                                label="Status"
                                onChange={(e) => {
                                  setEventObject({
                                    ...eventObject,
                                    extendedProps: {
                                      ...eventObject.extendedProps,
                                      status: e.target.value,
                                    },
                                  });
                                }}
                                variant="standard"
                                sx={{
                                  width: 120,
                                  "& .MuiInput-input": {
                                    padding: "0",
                                  },
                                }}
                              >
                                <MenuItem value={"Planerad"}>Planerad</MenuItem>
                                <MenuItem value={"Pågående"}>Pågående</MenuItem>
                                <MenuItem value={"Genomförd"}>
                                  Genomförd
                                </MenuItem>
                                <MenuItem value={"Vilande"}>Vilande</MenuItem>
                                <MenuItem value={"Avbruten"}>Avbruten</MenuItem>
                              </Select>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <DesktopDatePicker
                              className="datePicker"
                              label="Från"
                              inputFormat="DD/MM/YYYY"
                              value={eventObject.start}
                              onChange={(newValue) => {
                                setEventObject({
                                  ...eventObject,
                                  start: moment(newValue).format("YYYY-MM-DD"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField required {...params} />
                              )}
                            />
                            <DesktopDatePicker
                              className="datePicker"
                              label="Till"
                              inputFormat="DD/MM/YYYY"
                              value={eventObject.end}
                              onChange={(newValue) => {
                                setEventObject({
                                  ...eventObject,
                                  end: moment(newValue).format("YYYY-MM-DD"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField required {...params} />
                              )}
                            />
                          </Box>
                          <TextField
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "4px",
                            }}
                            required
                            id="outlined-required"
                            label="Event"
                            value={eventObject.title}
                            placeholder="Vad händer?"
                            onChange={(e) => {
                              setEventObject({
                                ...eventObject,
                                title: e.target.value,
                              });
                            }}
                          />
                          <TextField
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "4px",
                            }}
                            multiline
                            rows={6}
                            id="outlined-required"
                            label="Instruktioner"
                            value={eventObject.extendedProps.instructions}
                            onChange={(e) => {
                              setEventObject({
                                ...eventObject,
                                extendedProps: {
                                  ...eventObject.extendedProps,
                                  instructions: e.target.value,
                                },
                              });
                            }}
                          />

                          <CirclePicker
                            circleSize={14}
                            circleSpacing={7}
                            width={"100%"}
                            onSwatchHover={(e) => {
                              setColorPreview(e.hex);
                            }}
                            onChange={(e) => {
                              setEventObject({
                                ...eventObject,
                                color: e.hex,
                              });
                            }}
                            colors={colors}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              className="cancel-button"
                              onClick={handleClose}
                            >
                              <p className="-faded">Avbryt</p>
                            </button>
                            <button
                              className="-button -filled -green -xsmall"
                              type="submit"
                              onClick={() => {
                                getCompanyEvents();
                                updateEventBoolean(true);
                              }}
                            >
                              <p className="-bold -large">Spara</p>
                            </button>
                          </Box>
                        </Stack>
                      </form>
                    </LocalizationProvider>
                  </Box>
                </Modal>
                {/* EVENT DRAG AND DROP DISABLED FOR NOW. */}
                <FullCalendar
                  plugins={[interactionPlugin, dayGridPlugin]}
                  initialView="dayGridMonth"
                  selectable={true}
                  selectMirror={true}
                  contentHeight="auto"
                  editable={false}
                  eventResizableFromStart={false}
                  fixedWeekCount={false}
                  showNonCurrentDates={true}
                  eventStartEditable={false}
                  weekNumbers={true}
                  events={listAllEvents}
                  weekText=""
                  height={"100%"}
                  locale="sv"
                  weekends={true}
                  eventChange={() => {
                    handleEventChange();
                  }}
                  eventDragStart={function (info) {
                    handleDragStart(
                      info.event.startStr,
                      info.event.endStr,
                      info.event.title,
                      info.event.id,
                      info.event.extendedProps,
                      info.event.backgroundColor
                    );
                  }}
                  eventDragStop={(info) => {
                    handleDragEnd(
                      info.event.startStr,
                      info.event.endStr,
                      info.event.title,
                      info.event.id,
                      info.event.extendedProps,
                      info.event.backgroundColor
                    );
                  }}
                  firstDay={1}
                  buttonText={{ today: "Idag" }}
                  dayMaxEventRows={2}
                  select={function (info) {
                    handleOpen(info.startStr, info.endStr);
                  }}
                  eventClick={function (info) {
                    handleEdit(
                      info.event.startStr,
                      info.event.endStr,
                      info.event.title,
                      info.event.id,
                      info.event.extendedProps,
                      info.event.backgroundColor
                    );
                  }}
                />
              </Box>
              <Box className="sidebarContainer hide-scroll -padded-4">
                <Box className="-mb-2">
                  <h2>Kommande aktiviteter</h2>
                </Box>
                <Box className="flex flex-col justify-between h-full w-full">
                  <Box className="hide-scroll flex h-auto flex-col justify-start min-w-full overflow-auto -mb-2">
                    {listAllEvents.map((event: any, index: any) => (
                      <Sidebar
                        key={event.id}
                        color={event.color}
                        dateFrom={event.start}
                        dateTo={event.end}
                        state={event.extendedProps.status}
                        id={event.id}
                        title={event.title}
                      />
                    ))}
                  </Box>

                  <Modal open={uploadEventsModalIsOpen}>
                    <>
                      <Box sx={uploadEventsModalStyle}>
                        <Box className="flex justify-end">x</Box>
                        <Box className="flex flex-col">
                          <Box>
                            <form
                              action="post"
                              onSubmit={(e) => {
                                handleEventUpload(e);
                              }}
                            >
                              <input
                                type="file"
                                name="csv"
                                accept=".csv"
                                onChange={(e) => {
                                  handleEventsToUpload(e);
                                }}
                              />

                              <button type="submit">Ladda upp event</button>
                            </form>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  </Modal>

                  <Box className="flex flex-col justify-end min-w-full  z-50">
                    <Box>
                      <button
                        onClick={() => downloadCSVdata()}
                        className="-button -filled -borderBlack -min-w-full -mb-2"
                      >
                        Ladda ner aktivitetsplan
                      </button>
                    </Box>
                    <Box mb={2}>
                      <button
                        onClick={() =>
                          orderIsOpen
                            ? handleOrderForm(false)
                            : handleOrderForm(true)
                        }
                        className="-button -filled -black -min-w-full"
                      >
                        Beställ material
                      </button>
                    </Box>
                    <Box className="flex justify-center">
                      <a
                        onClick={() => setUploadEventsModalIsOpen(true)}
                        className="cursor-pointer"
                      >
                        <span>Ladda upp aktiviteter (.csv)</span>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </>
  );
}

export default ActivityPlan;
