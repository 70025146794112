import { Box, Modal, TextField } from "@mui/material";
import React from "react";
import Image from "mui-image";
import "./fontsModal.scss";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CompanyContext } from "../../../context/CompanyContext";
import { UserAuthContext } from "../../../context/UsersContext";
import { useContext, useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function getModalStyle() {
	const top = 50;
	const left = 50;
	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}
function FontsModal(prop: { show: any; close: any }) {
	const { companyId, isAdmin, isOwner } = useContext(UserAuthContext);
	const { listAllFonts, getCompanyFonts, addFont, deleteFont } =
		useContext(CompanyContext);

	const [modalStyle] = React.useState(getModalStyle);
	const [newFont, setNewFont] = useState<any>({});
	const [updateList, setUpdateList] = useState<any>(listAllFonts);

	const [show, setShow] = useState(false);

	function updateFont(id: string, value: string) {
		setNewFont({
			...newFont,
			[id]: value,
		});
	}

	useEffect(() => {
		getCompanyFonts();
	}, [companyId]);

	useEffect(() => {
		getCompanyFonts();
	}, [updateList]);

	const updateState = (list: any) => {
		setUpdateList(list);
	};

	const handleAddFont = () => {
		addFont(newFont);
		updateState(listAllFonts);
		setShow(false);
	};

	const handleDeleteFont = (font: any) => {
		setUpdateList(listAllFonts);
		deleteFont(font);
	};

	function handleChange(
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
	) {
		if (!event.target.id) {
			updateFont(event.target.name, event.target.value);
		} else {
			updateFont(event.target.id, event.target.value);
		}
	}
	return (
		<div>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={prop.show}
				onClose={prop.close}
				style={{ position: "absolute" }}
				container={() => document.getElementById("dashboard-container")}
				BackdropProps={{ style: { position: "absolute" } }}
			>
				<div style={modalStyle} className="modalPopup">
					<button className="closeButton">
						<CloseRoundedIcon
							fontSize="large"
							className="closeButton"
							onClick={prop.close}
						/>
					</button>
					<Box>
						<p className="-large -bold -pb-3 -pt-3">Mina typsnitt</p>
						<p className="">
							På grund av licensrättigheter, duis ornare elementum justo ac
							dignissim. In hac habitasse platea dictumst.
						</p>
					</Box>
					<Box className="-pt-3" sx={{ width: "100%" }}>
						{listAllFonts?.map((font: any, index: any) => (
							<Box
								key={index}
								className="-pb-3"
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "flex-end",
									width: "100%",
								}}
							>
								<>
									<p className=" -bold">{font.name}</p>
									<Box className="flex flex-row items-end">
										<a className="green-link" href={font.url} target="_blank">
											Gå till webbplats
										</a>

										{isOwner || isAdmin ? (
											<DeleteForeverIcon
												className="cursor-pointer delete-font"
												sx={{
													marginLeft: ".5rem",
													color: "black",
												}}
												onClick={() => handleDeleteFont(font)}
											/>
										) : null}
									</Box>
								</>
							</Box>
						))}
						{isOwner || isAdmin ? (
							<Box className="addButton -pb-3">
								<p onClick={() => setShow((prev) => !prev)}>
									{!show && "+ Lägg till fler typsnitt"}
								</p>
								{show && (
									<Box>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "flex-end",
												height: "100%",
												width: "100%",
											}}
										>
											<TextField
												autoComplete="off"
												fullWidth
												id="name"
												required
												inputProps={{ maxLength: 30 }}
												onChange={handleChange}
												className="inputField"
												variant="standard"
												margin="normal"
												label="Typsnitt"
												placeholder="Times New Roman"
											/>
											<TextField
												autoComplete="off"
												fullWidth
												id="url"
												required
												onChange={handleChange}
												className="inputField"
												variant="standard"
												margin="normal"
												label="Webbplats"
												placeholder="https://www.url.se"
											/>
										</Box>
									</Box>
								)}
							</Box>
						) : null}
					</Box>
					{!show ? (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								height: "100%",
								width: "50%",
							}}
						>
							<button onClick={prop.close} className="-button -filled -black">
								Stäng fönster
							</button>
						</Box>
					) : (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								height: "100%",
								width: "50%",
							}}
						>
							<button
								onClick={handleAddFont}
								className="-button -filled -black"
							>
								Lägg till
							</button>
						</Box>
					)}
				</div>
			</Modal>
		</div>
	);
}

export default FontsModal;
