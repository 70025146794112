import { Box } from "@mui/material";
import React, { useState } from "react";
import { IncomingUser } from "../../../types/types";
import "./logInForm.scss";
interface Iprops {
	loginDataCallback(user: IncomingUser): void;
	incorrectInfo: boolean;
}

function LoginInputForm(props: Iprops) {
	const [incomingUser, setIncomingUser] = useState<IncomingUser>({
		email: "",
		password: "",
	});

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setIncomingUser({
			...incomingUser,
			[event.target.id]: event.target.value,
		});
	}

	function handleSubmit(event: React.SyntheticEvent) {
		event.preventDefault();
		props.loginDataCallback(incomingUser);
	}

	return (
		<Box>
			<div className="w-full">
				<form
					className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md logInFormContainer"
					onSubmit={handleSubmit}
				>
					<div className="mb-4">
						<label
							className="block mb-2 text-sm font-bold text-gray-700"
							htmlFor="email"
						>
							Email
						</label>
						<input
							onChange={handleChange}
							className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
							id="email"
							type="text"
							placeholder="Email"
						/>
					</div>
					<div className="mb-6">
						<label
							className="block mb-2 text-sm font-bold text-gray-700"
							htmlFor="password"
						>
							Password
						</label>
						<input
							className="w-full px-3 py-2 mb-3 leading-tight text-gray-700 border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
							id="password"
							type="password"
							placeholder="******************"
							onChange={handleChange}
						/>
						{props.incorrectInfo ? (
							<p className="text-xs italic text-red-500">
								Something went wrong. Please check your email or password.
							</p>
						) : null}
					</div>
					<div className="flex items-center justify-center">
						<button
							className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-800 focus:outline-none focus:shadow-outline"
							type="submit"
						>
							Sign In
						</button>
					</div>
				</form>
				<p className="text-xs text-center text-gray-500">
					&copy;2022 Utmärkt. All rights reserved.
				</p>
			</div>
		</Box>
	);
}

export default LoginInputForm;
