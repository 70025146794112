// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDy1Sz328tnmriJsBN2o2w7LS_wV5g9EwM",
	authDomain: "brandie-7db28.firebaseapp.com",
	projectId: "brandie-7db28",
	storageBucket: "brandie-7db28.appspot.com",
	messagingSenderId: "96369736501",
	appId: "1:96369736501:web:9560f14840a358d352ea7c",
	measurementId: "G-CWRRMHLPET",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
