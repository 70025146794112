import { Box } from "@mui/material";
import { useContext } from "react";
import Sideboard from "../sideboard/Sideboard";
import SideboardAdmin from "../sideboard/SideboardAdmin";
import Header from "../header/Header";
import { Navigate } from "react-router-dom";
import { UserAuthContext } from "../../../context/UsersContext";

import ContactForm from "../contact/ContactForm";
import OrderForm from "../contact/OrderForm";

import "./content.scss";
import Footer from "../footer/Footer";
interface Iprops {
	children?: JSX.Element | JSX.Element[];
}

function Content(props: Iprops) {
	const { loggedIn, isAdmin } = useContext(UserAuthContext);

	return (
		<Box className="contentContainer">
			{!loggedIn ? <Navigate to={`/login`} /> : null}
			{isAdmin ? <SideboardAdmin /> : <Sideboard />}
			<Box
				id="dashboard-container"
				className="dashboard-container -flex -flex-column -justify-center -align-center"
			>
				<Box className="headerContainer">{<Header />}</Box>
				<OrderForm />
				<ContactForm />
				<Box className="dashboard-content -flex -flex-wrap">
					{props.children}
				</Box>
				<Footer />
			</Box>
		</Box>
	);
}

export default Content;
