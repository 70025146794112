import { createContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { User, UserInfoToUpdate, Company } from "../types/types";
import { onAuthStateChanged, updatePassword } from "firebase/auth";

interface IState {
	loggedIn: boolean;
	loading: boolean;
	defaultCompany: string | null;
	companyId: string | null;
	userID: string | null;
	userInformation: User | null;
	isAdmin: boolean;
	isOwner: boolean;
	companyInformation: Company | null;
	activeCompany: any | null;
	contactIsOpen: boolean;
	orderIsOpen: boolean;
}

interface ContextValue extends IState {
	updateUserPassword: (password: string) => void;
	updateUserInformation: (userID: string, data: UserInfoToUpdate) => void;
	updateActiveCompany: (value: string) => void;
	getCompanyInformation: (companyId: string) => void;
	handleContactForm: (state: boolean) => void;
	handleOrderForm: (state: boolean) => void;
}

export const UserAuthContext = createContext<ContextValue>({
	updateUserInformation: () => {},
	updateUserPassword: () => {},
	updateActiveCompany: () => {},
	getCompanyInformation: () => {},
	handleContactForm: () => {},
	handleOrderForm: () => {},
	loggedIn: false,
	loading: true,
	userID: null,
	userInformation: {} as User,
	defaultCompany: null,
	isAdmin: false,
	isOwner: false,
	companyInformation: {} as Company,
	activeCompany: null,
	companyId: null,
	contactIsOpen: false,
	orderIsOpen: false,
});

interface Props {
	children: any;
}

function UserAuthProvider(props: Props) {
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [userID, setUserID] = useState<string | null>(null);
	const [userInformation, setUserInformation] = useState<User | null>(null);
	const [activeCompany, setActiveCompany] = useState<any | null>(null);
	const [companyInformation, setCompanyInformation] = useState<any | null>(
		null
	);
	const [savedCompany] = useState<any>(localStorage.getItem("company"));
	const [defaultCompany, setDefaultCompany] = useState<string | null>(
		savedCompany ? window.localStorage.getItem("company") : null
	);
	const [companyId, setCompanyId] = useState<string | null>(null);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const [isOwner, setisOwner] = useState<boolean>(false);
	const [contactIsOpen, setContactIsOpen] = useState<boolean>(false);
	const [orderIsOpen, setOrderIsOpen] = useState<boolean>(false);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setLoggedIn(true);
				setUserID(user.uid);
				getUserInformation(user.uid);
			} else {
				setUserID(null);
				setLoggedIn(false);
				setIsAdmin(false);
			}
		});
	}, []);

	useEffect(() => {
		if (companyId) {
			getCompanyInformation(companyId);
		}
	}, [companyId]);

	useEffect(() => {
		if (activeCompany) {
			setCompanyId(activeCompany.companyId);
		}
	}, [activeCompany]);

	useEffect(() => {
		// checks if local storage is defined. If not: fall back to default company.
		if (window.localStorage.getItem("company") === null || "undefined") {
			setActiveCompany(defaultCompany);
		} else {
			setActiveCompany(window.localStorage.getItem("company")!);
		}
	}, [defaultCompany]);

	useEffect(() => {
		if (window.localStorage.getItem("company") !== "undefined" || null) {
			setActiveCompany(window.localStorage.getItem("company"));
		}
	}, []);

	useEffect(() => {
		if (activeCompany !== null || "undefined") {
			// sets local storage value to current active company
			if (companyInformation) {
				window.localStorage.setItem("company", activeCompany.companyName);
			}
		} else {
			setActiveCompany(window.localStorage.getItem("company"));
		}
	}, [companyInformation]);

	function handleContactForm(state: boolean) {
		setContactIsOpen(state);
	}

	function handleOrderForm(state: boolean) {
		setOrderIsOpen(state);
	}

	async function getUserInformation(userID: string) {
		const docRef = doc(db, "users", userID);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setUserInformation(docSnap.data().userInformation);

			setDefaultCompany(docSnap.data().userInformation.userCompanies[0]);

			if (docSnap.data().userInformation.role === "admin") {
				setIsAdmin(true);
			}

			if (docSnap.data().userInformation.role === "owner") {
				setisOwner(true);
			}

			setLoading(false);
		}
	}

	async function updateUserPassword(password: string) {
		const user = auth.currentUser;
		if (user) {
			await updatePassword(user, password);
		}
	}

	async function getCompanyInformation(companyId: string) {
		const docRef = doc(db, "Companies", companyId);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setCompanyInformation(docSnap.data());
		}
	}

	function updateActiveCompany(value: string) {
		setActiveCompany(value);
	}

	async function updateUserInformation(userID: string, data: UserInfoToUpdate) {
		const docRef = doc(db, "users", userID);
		await updateDoc(docRef, {
			"userInformation.email": data.email,
			"userInformation.firstName": data.firstName,
			"userInformation.lastName": data.lastName,
			"userInformation.phoneNumber": data.phoneNumber,
			"userInformation.role": data.role,
			"userInformation.rID": data.rID,
			"userInformation.userCompanies": data.userCompanies,
		});
	}

	return (
		<UserAuthContext.Provider
			value={{
				loggedIn: loggedIn,
				loading: loading,
				userID: userID,
				defaultCompany: defaultCompany,
				userInformation: userInformation,
				isAdmin: isAdmin,
				isOwner: isOwner,
				activeCompany: activeCompany,
				companyId: companyId,
				companyInformation: companyInformation,
				contactIsOpen: contactIsOpen,
				orderIsOpen: orderIsOpen,
				handleOrderForm: handleOrderForm,
				handleContactForm: handleContactForm,
				updateUserInformation: updateUserInformation,
				updateUserPassword: updateUserPassword,
				updateActiveCompany: updateActiveCompany,
				getCompanyInformation: getCompanyInformation,
			}}
		>
			{props.children}
		</UserAuthContext.Provider>
	);
}

export const UserAuthConsumer = UserAuthContext.Consumer;
export default UserAuthProvider;
