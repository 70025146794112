import DashboardCard from "../dashboardCard/dashboardCard";
import "./documents.scss";
import { UserAuthContext } from "../../../context/UsersContext";
import { useContext, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";

import FileManager from "../graphicProfile/fileManager/FileManager";
function Documents() {
	const { companyInformation, companyId, getCompanyInformation } =
		useContext(UserAuthContext);

	useEffect(() => {
		if (companyId) {
			getCompanyInformation(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				overflow: "auto",
			}}
			className="min-w-[70%] -mt-4 "
		>
			{companyId && companyInformation ? (
				<FileManager text="documents" id={companyId} />
			) : (
				<CircularProgress />
			)}
		</Box>
	);
}

export default Documents;
