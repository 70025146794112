import { Box, Breadcrumbs as MUIBreadcrumbs, Link } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import "./breadcrumbs.scss";

const Breadcrumbs = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const pathnames = pathname.split("/").filter(Boolean);

	return (
		<MUIBreadcrumbs aria-label="breadcrumb" key={pathname}>
			{pathnames.length ? <Link onClick={() => navigate("/")}>Hem</Link> : null}
			{pathnames.map((name, index) => {
				const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
				const isLast = index === pathnames.length - 1;

				return isLast ? (
					<Box
						key={pathname}
						sx={{
							display: "flex",
						}}
					>
						{name == "logotypes" && (
							<>
								<Link
									key={pathname}
									onClick={() => navigate("/graphicprofile")}
								>
									Grafisk profil
								</Link>
								<li className={"MuiBreadcrumbs-separator"}> / </li>
							</>
						)}
						{name == "branding" && (
							<>
								<Link
									key={pathname}
									onClick={() => navigate("/graphicprofile")}
								>
									Grafisk profil
								</Link>
								<li className={"MuiBreadcrumbs-separator"}> / </li>
							</>
						)}
						{name == "fonts" && (
							<>
								<Link
									key={pathname}
									onClick={() => navigate("/graphicprofile")}
								>
									Grafisk profil
								</Link>
								<li className={"MuiBreadcrumbs-separator"}> / </li>
							</>
						)}
						<p key={name}>
							{name == "graphicprofile" && "Grafisk profil"}
							{name == "logotypes" && "Logokit"}
							{name == "branding" && "Branding"}
							{name == "fonts" && "Typsnitt"}
							{name == "activityplan" && "Aktivitetsplan"}
							{name == "settings" && "Inställningar"}
							{name == "graphictemplates" && "Grafiska mallar"}
							{name == "documents" && "Dokument"}
							{name == "creativeboost" && "Kreativ boost"}
						</p>
					</Box>
				) : (
					<Link key={name} onClick={() => navigate(routeTo)}>
						{name}
					</Link>
				);
			})}
		</MUIBreadcrumbs>
	);
};

export default Breadcrumbs;
