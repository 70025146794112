import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import "./sideboard.scss";
import MenuAdmin from "./MenuAdmin";

import { useLocation } from "react-router-dom";

interface Iprops {
	children?: JSX.Element | JSX.Element[];
}

function SideboardAdmin(props: Iprops) {
	const [selectedIndex, setSelectedIndex] = useState<any>("");
	const [active, setActive] = useState(false);

	// 👇️ with React router
	const location = useLocation();

	useEffect(() => {
		setSelectedIndex(location.pathname);
	}, [location.pathname]);

	// lägg in dom andra pathsen när vi fixat routsen

	useEffect(() => {
		if (selectedIndex === "/fonts") {
			setSelectedIndex("/graphicprofile");
		}
	}, [selectedIndex]);

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: any
	) => {
		setSelectedIndex(index);
	};

	return (
		<>
			<Box className="hide-menu">
				<MenuAdmin
					selectedIndex={selectedIndex}
					handleListItemClick={handleListItemClick}
				/>
			</Box>
			{/* Mobil-menu  */}
			<Box onClick={() => setActive(!active)} className="wrapper">
				<Box className={active ? "activeHamburger" : "hamburger"} />
				<Box className={active ? "activeSidenav" : "sidenav"}>
					<Box className="sideboardContainerMobile">
						<MenuAdmin
							selectedIndex={selectedIndex}
							handleListItemClick={handleListItemClick}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
}

export default SideboardAdmin;
