import "./upload.scss";
import { StorageContext } from "../../../context/StorageContext";
import { useCallback, useContext } from "react";
import {
	Box,
	LinearProgress,
	LinearProgressProps,
	Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Lottie from "lottie-react";
import upload from "../../../assets/lottie/uploading.json";
interface Iprops {
	destination: string;
	coverPhoto?: boolean;
}

function FileUpload(props: Iprops) {
	const {
		uploadFile,
		uploadCoverPhoto,
		handleChange,
		file,
		convertFilesize,
		percent,
		showProgressBar,
		showFileName,
	} = useContext(StorageContext);

	const onDrop = useCallback((acceptedFiles: any) => {
		// Do something with the files
		handleChange(acceptedFiles);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } =
		useDropzone({
			onDrop,
			noClick: true,
		});

	function LinearProgressWithLabel(
		props: LinearProgressProps & { value: number }
	) {
		return (
			<Box className="flex items-center">
				<Box className="w-100 mr-1 min-w-[35rem]">
					<LinearProgress variant="determinate" {...props} />
				</Box>
				<Box>
					<p className="-large">{`${Math.round(percent)}%`}</p>
				</Box>
			</Box>
		);
	}

	const files = acceptedFiles.map((file) => (
		<li key={file.name} className="text-black">
			{file.name} - {convertFilesize(file.size)}
		</li>
	));

	return (
		<>
			<div
				{...getRootProps()}
				className={
					isDragActive ? "uploadContainer  -active" : "uploadContainer"
				}
			>
				<input {...getInputProps()} />
				<Box className="flex flex-col justify-center h-[8.5rem] w-100 min-w-[17.8rem] items-center transition-all duration-200">
					{file !== "" && files.length > 0 ? (
						<Box
							onClick={() => {
								props.coverPhoto
									? uploadCoverPhoto(props.destination, acceptedFiles[0].type)
									: uploadFile(props.destination, acceptedFiles[0].type);
							}}
							className="flex flex-col justify-center items-center h-[8.5rem] w-full min-w-[17.8rem] transition-all duration-200 bg-[#47FF97] rounded-[10px] cursor-pointer"
						>
							<>
								{files.length > 0 && !showProgressBar ? (
									<p className="text-black -bold">
										Tryck här för att ladda upp
									</p>
								) : (
									<p className="text-black -bold">Laddar upp...</p>
									// <Lottie animationData={upload} />
								)}
								{files.length > 0 && (
									<Box className="flex flex-col items-center justify-center">
										{showFileName && (
											<ul className="-mt-2 text-center">{files}</ul>
										)}
										{showProgressBar && (
											<Box
												sx={{
													paddingTop: "1rem",
												}}
											>
												<LinearProgress
													variant="determinate"
													value={percent}
													color="primary"
													style={{
														// color: "#75A0F5",
														borderRadius: "10px",
														height: "8px",
														maxWidth: "17rem",
														minWidth: "14rem",
													}}
												/>
											</Box>
										)}
									</Box>
								)}
							</>
						</Box>
					) : (
						<Box className="flex flex-col-reverse items-center justify-center w-full -padded-2">
							<Box className="flex flex-col items-center justify-center w-full">
								<p
									className={
										isDragActive
											? "uploadText w-full select-none"
											: "uploadText -invis w-full select-none"
									}
								>
									Släpp filen här!
								</p>
								<button type="button" onClick={open}>
									<p
										className={
											!isDragActive
												? "uploadText w-full px-8"
												: "uploadText -invis px-8 w-full"
										}
									>
										Dra din fil hit eller klicka för att välja.
									</p>
									<Box className="flex justify-center w-full">
										<FileUploadOutlinedIcon
											sx={{
												fontSize: "50px",
												fontWeight: "thin",
											}}
										/>
									</Box>
								</button>
							</Box>
						</Box>
					)}
				</Box>
			</div>
		</>
	);
}

export default FileUpload;
