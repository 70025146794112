import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { collectionGroup, query, getDocs } from "firebase/firestore";

import { UserAuthContext } from "./UsersContext";
interface IState {
	listAllUsers: any;
	listAllCompanies: any;
}

interface ContextValue extends IState {
	getAllUsers: () => void;
	getAllCompanies: () => void;
}

export const SettingsContext = createContext<ContextValue>({
	getAllUsers: () => {},
	getAllCompanies: () => {},
	listAllUsers: [],
	listAllCompanies: [],
});

interface Props {
	children: any;
}

function SettingsProvider(props: Props) {
	const { isAdmin } = useContext(UserAuthContext);
	const [listAllUsers, setListAllUsers] = useState<any>("");
	const [listAllCompanies, setListAllCompanies] = useState<any>("");
	useEffect(() => {
		getAllUsers();
		getAllCompanies();
	}, [isAdmin]);

	const getAllUsers = async () => {
		let userList: any = [];
		const users = query(collectionGroup(db, "users"));
		const querySnapshot = await getDocs(users);
		querySnapshot.forEach((doc) => {
			userList.push(doc.data());
		});
		setListAllUsers(userList);
	};

	const getAllCompanies = async () => {
		let companyList: any = [];
		const companies = query(collectionGroup(db, "Companies"));
		const querySnapshot = await getDocs(companies);
		querySnapshot.forEach((doc) => {
			companyList.push(doc.data());
		});
		setListAllCompanies(companyList);
	};

	return (
		<SettingsContext.Provider
			value={{
				getAllUsers: () => {},
				getAllCompanies: () => {},
				listAllUsers: listAllUsers,
				listAllCompanies: listAllCompanies,
			}}
		>
			{props.children}
		</SettingsContext.Provider>
	);
}

export const SettingsConsumer = SettingsContext.Consumer;
export default SettingsProvider;
