import "./fileManager.scss";
import { UserAuthContext } from "../../../../context/UsersContext";
import { StorageContext } from "../../../../context/StorageContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	Alert,
	Box,
	CircularProgress,
	Snackbar,
	Tooltip,
	TooltipProps,
	tooltipClasses,
	styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";

import ListItem from "./ListItem";
import FileUpload from "../../fileUpload/FileUpload";

import boxShake from "../../../../assets/lottie/box-shake.json";

function FileManager(prop: { text: string; id?: string | null }) {
	const {
		isAdmin,
		isOwner,
		activeCompany,
		getCompanyInformation,
		companyInformation,
	} = useContext(UserAuthContext);

	const {
		downloadFolderAsZip,
		handleClose,
		listAllFilesInFolder,
		arrayIsCleared,
		open,
		uploadedFile,
		arrayToMap,
		fileWasDeleted,
		deletedFile,
	} = useContext(StorageContext);

	const [destination, setDestination] = useState(`/${prop.text}`);
	const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
	const navigate = useNavigate();
	const location = useLocation();

	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}));

	useEffect(() => {
		if (prop.id) {
			getCompanyInformation(prop.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCompany]);

	useEffect(() => {
		listAllFilesInFolder(location.pathname);

		setDestination(location.pathname);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyInformation]);

	useEffect(() => {
		if (prop.id) {
			getCompanyInformation(prop.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCompany]);

	useEffect(() => {
		setIsDataUpdated(!isDataUpdated);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arrayIsCleared]);

	function checkIfStringIsTooLongForSnackbar(string: string) {
		if (string.length >= 20) {
			return "...";
		} else return "";
	}

	return (
		<Box
			className={"row-to-column"}
			sx={{
				width: "100%",
				justifyContent: "center",
			}}
		>
			<>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						paddingRight: "2rem",
						overflow: "auto",
						height: "100%",
					}}
					className="min-h-[40rem]"
				>
					<Box>
						{isAdmin || isOwner ? <FileUpload destination={prop.text} /> : null}
						<Box className="min-w-full">
							<button
								className="-mt-1 -button"
								onClick={() => downloadFolderAsZip(prop.text)}
							>
								<p className="-large -bold">
									{prop.text === "branding"
										? "Ladda ner manual"
										: prop.text === "logotypes"
										? "Ladda ner logokit"
										: prop.text === "documents"
										? "Ladda ner dokument"
										: prop.text === "graphictemplates"
										? "Ladda ner grafiska mallar"
										: null}
								</p>
							</button>
							{/* <Box className="flex justify-around min-w-full mt-2 text-white">
								<LightTooltip title={"Öppna filhanteraren"}>
									<Link to={`/${prop.text}`} className="text-inherit">
										<FolderOpenOutlinedIcon fontSize="large" />
									</Link>
								</LightTooltip>
								<LightTooltip title={"Funktionen kommer snart"}>
									<DriveFileMoveOutlinedIcon
										sx={{ color: "#555f7f" }}
										fontSize="large"
									/>
								</LightTooltip>
								<LightTooltip title={"Funktionen kommer snart"}>
									<ShareOutlinedIcon
										sx={{ color: "#555f7f" }}
										fontSize="large"
									/>
								</LightTooltip>
							</Box> */}
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							fontSize: "20px",
						}}
						onClick={() => navigate(-1)}
						className="cursor-pointer"
					>
						<button className="-button">Tillbaka</button>
					</Box>
				</Box>

				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
					<Alert severity="success" sx={{ width: "100%" }}>
						{`${uploadedFile}${checkIfStringIsTooLongForSnackbar(
							uploadedFile
						)} was uploaded`}
					</Alert>
				</Snackbar>
				<Snackbar
					open={fileWasDeleted}
					autoHideDuration={6000}
					onClose={handleClose}
				>
					<Alert severity="error" sx={{ width: "100%", zIndex: 9999 }}>
						{`${deletedFile}${checkIfStringIsTooLongForSnackbar(
							deletedFile
						)} was deleted`}
					</Alert>
				</Snackbar>

				<Box className="fileManagerContainer">
					<Box className="w-full fileManagerListContainer">
						{arrayToMap.length > 0 ? (
							<Box className="listController">
								{isDataUpdated
									? arrayToMap.map((data: any) => (
											<ListItem
												key={data.md5Hash}
												name={data.name}
												size={data.size}
												destination={destination}
												url={data.customMetadata.downloadUrl}
												type={data.contentType}
												date={data.timeCreated}
											/>
									  ))
									: null}
							</Box>
						) : (
							<Box className="flex flex-col items-center">
								<Box className="max-w-[25rem]">
									<Lottie animationData={boxShake} loop={true} />
								</Box>
								<Box className="flex flex-col items-center gap-4 text-black">
									<h2>Här var det tomt!</h2>
									<p className="-large">
										Börja med att ladda upp ett par filer.
									</p>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</>
		</Box>
	);
}

export default FileManager;
